import React from "react";
import "./style/DescTable.css";

export default function DescTable() {
  return (
    <div class="a-rows" style={{ color: "black" }}>
      <div class=" ">
        <div class="">
          <h1 class="font-size-medium ">
            Technical Details / Specifications
          </h1>
          <hr></hr>
        </div>
      
              <table
                id=""
                class=""
                
              >
                <tbody>
                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      OS
                    </th>

                    <td class="a-size-base prodDetAttrValue">Android</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      RAM
                    </th>

                    <td class="a-size-base prodDetAttrValue">4 GB</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Product Dimensions
                    </th>

                    <td class="a-size-base prodDetAttrValue">
                      16.4 x 0.8 x 7.6 cm; 180 Grams
                    </td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Batteries
                    </th>

                    <td class="a-size-base prodDetAttrValue">
                      1 Lithium Polymer batteries required. (included)
                    </td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Item model number
                    </th>

                    <td class="a-size-base prodDetAttrValue">CPH2015</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Wireless communication technologies
                    </th>

                    <td class="a-size-base prodDetAttrValue">
                      Bluetooth, WiFi Hotspot
                    </td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Connectivity technologies
                    </th>

                    <td class="a-size-base prodDetAttrValue">4g</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Special features
                    </th>

                    <td class="a-size-base prodDetAttrValue">
                      Acceleration sensor, Magnetic induction, Fingerprint,
                      Proximity Sensor, Dual SIM, OTG, GPS, Pedometer, G-sensor,
                      Light sensor, Other sensors, Video Player, Music Player
                    </td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Other display features
                    </th>

                    <td class="a-size-base prodDetAttrValue">Wireless</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Device interface - primary
                    </th>

                    <td class="a-size-base prodDetAttrValue">Touchscreen</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Other camera features
                    </th>

                    <td class="a-size-base prodDetAttrValue">12MP+2MP+2MP</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Form factor
                    </th>

                    <td class="a-size-base prodDetAttrValue">Smartphone</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Colour
                    </th>

                    <td class="a-size-base prodDetAttrValue">Mystery Black</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Battery Power Rating
                    </th>

                    <td class="a-size-base prodDetAttrValue">
                      4230 Milliamp Hours
                    </td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Phone Talk Time
                    </th>

                    <td class="a-size-base prodDetAttrValue">45 Hours</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Phone Standby Time (with data)
                    </th>

                    <td class="a-size-base prodDetAttrValue">450 Hours</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Whats in the box
                    </th>

                    <td class="a-size-base prodDetAttrValue">
                      Handset, USB Cable, Sim Tray Ejecter, Pre-applied Screen
                      Protector and Protective Case, Booklet with Warranty Card
                      and Quick Guide
                    </td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Manufacturer
                    </th>

                    <td class="a-size-base prodDetAttrValue">
                      OPPO Mobiles India Pvt. Ltd.
                    </td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Country of Origin
                    </th>

                    <td class="a-size-base prodDetAttrValue">India</td>
                  </tr>

                  <tr>
                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                      Item Weight
                    </th>

                    <td class="a-size-base prodDetAttrValue">180 g</td>
                  </tr>
                </tbody>
              </table>
         
          
        <div class="a-row a-spacing-base"></div>
        <div class="a-row"></div>
        {/* </div>
            <div class="a-column a-span6 a-span-last">
                <div class="a-row a-spacing-base"> */}

        {/* 
                    <div id="productDetails_db_sections" class="a-section">



                        <h1 class="a-size-medium a-spacing-small secHeader">Additional Information</h1>




                        <div class="a-section table-padding">
                            <table id="productDetails_detailBullets_sections1" class="a-keyvalue prodDetTable" role="presentation">








                                <tbody><tr>




                                    <th class="a-color-secondary a-size-base prodDetSectionEntry">
                                        ASIN
</th>






                                    <td class="a-size-base prodDetAttrValue">
                                        B084454YKR
</td>



                                </tr>










                                    <tr>


                                        <th class="a-color-secondary a-size-base prodDetSectionEntry">Customer Reviews</th>

                                        <td class="a-size-base">
                                            <style type="text/css">

                                            </style>



















                                            <div id="averageCustomerReviews" data-asin="B084454YKR" data-ref="dpx_acr_pop_">












                                                <span class="a-declarative" data-action="acrStarsLink-click-metrics" data-acrstarslink-click-metrics="{}">



                                                    <span id="acrPopover" class="reviewCountTextLinkedHistogram noUnderline" title="4.1 out of 5 stars">
                                                        <span class="a-declarative" data-action="a-popover" data-a-popover="{&quot;max-width&quot;:&quot;700&quot;,&quot;closeButton&quot;:&quot;false&quot;,&quot;position&quot;:&quot;triggerBottom&quot;,&quot;url&quot;:&quot;/gp/customer-reviews/widgets/average-customer-review/popover/ref=dpx_acr_pop_?contextId=dpx&amp;asin=B084454YKR&quot;}">
                                                            <a href="javascript:void(0)" class="a-popover-trigger a-declarative">

                                                                <i class="a-icon a-icon-star a-star-4"><span class="a-icon-alt">4.1 out of 5 stars</span></i>

                                                                <i class="a-icon a-icon-popover"></i></a>
                                                        </span>
                                                        <span class="a-letter-space"></span>
                                                    </span>



                                                </span>
                                                <span class="a-letter-space"></span>












                                                <span class="a-declarative" data-action="acrLink-click-metrics" data-acrlink-click-metrics="{}">
                                                    <a id="acrCustomerReviewLink" class="a-link-normal" href="#customerReviews">
                                                        <span id="acrCustomerReviewText" class="a-size-base">4,830 ratings</span>
                                                    </a>
                                                </span>













                                            </div>


                                            <br></br>
                                                4.1 out of 5 stars
</td>
                                    </tr>













                                    <tr>
                                        <th class="a-color-secondary a-size-base prodDetSectionEntry">
                                            Best Sellers Rank
</th>
                                        <td>
                                            <span>

                                                <span>#265 in Electronics (<a href="/gp/bestsellers/electronics/ref=pd_zg_ts_electronics">See Top 100 in Electronics</a>)</span>
                                                <br></br>

                                                <span>#52 in <a href="/gp/bestsellers/electronics/1805560031/ref=pd_zg_hrsr_electronics">Smartphones</a></span>
                                                <br></br>

                                            </span>
                                        </td>
                                    </tr>
























                                    <tr>




                                        <th class="a-color-secondary a-size-base prodDetSectionEntry">
                                            Date First Available
</th>






                                        <td class="a-size-base prodDetAttrValue">
                                            22 January 2020
</td>



                                    </tr>









                                    <tr>




                                        <th class="a-color-secondary a-size-base prodDetSectionEntry">
                                            Manufacturer
</th>






                                        <td class="a-size-base prodDetAttrValue">
                                            OPPO Mobiles India Pvt. Ltd., 18001032777
</td>



                                    </tr>









                                    <tr>




                                        <th class="a-color-secondary a-size-base prodDetSectionEntry">
                                            Item Dimensions LxWxH
</th>






                                        <td class="a-size-base prodDetAttrValue">
                                            16.4 x 0.8 x 7.6 Centimeters
</td>



                                    </tr>









                                    <tr>




                                        <th class="a-color-secondary a-size-base prodDetSectionEntry">
                                            Generic Name
</th>






                                        <td class="a-size-base prodDetAttrValue">
                                            Smartphone
</td>



                                    </tr>





                                </tbody></table>
                        </div>

                    </div> */}

        {/* 
                </div>
                <div class="a-row a-spacing-base">



                </div>
                <div class="a-row">





                </div> */}
      </div>
    </div>
  );
}
