import React, { useState } from "react";

import Sliders from "../modules/Sliders.js";

import Clothing from "../modules/BodyModules/Clothing";
import ProductsCards from "../modules/BodyModules/ProductsCards";
import ProductsSlider from "../modules/BodyModules/ProductsSlider";
import ProductsDeal from "../modules/BodyModules/ProductsDeal";

export default function HomeScreen() {
  return (
    <div>
      <Sliders />

      <ProductsDeal />
      <Clothing />

      <ProductsSlider />
      <ProductsCards />
    </div>
  );
}
