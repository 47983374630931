import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import "./App.css";

import "bootstrap/dist/css/bootstrap.css";

import Header from "./modules/FixedModules/Header";
import Options from "./modules/Options.js";
import Footer from "./modules/FixedModules/Footer.js";

import ProductList from "./Pages/ProductList/ProductList";
import HomeScreen from "./modules/HomeScreen";
import SingleProduct from "./Pages/SingleProduct/SingleProduct";
import NotFound from "./modules/FixedModules/NotFound";
import Breadcrumb from "./modules/FixedModules/Breadcrumb";
import { CartPage } from "./Pages/CartPage/CartPage";
import { Checkout } from "./Pages/Checkout/Checkout";

import { SignIn } from "./Pages/SignIn/SignIn";
import { SignUp } from "./Pages/SignUp/SignUp";
import { SubCategories } from "./Pages/SubCategories/SubCategories";
import CardDetails from "./Pages/CardDetails/CardDetails";
import { OrderHistory } from "./Pages/OrderHistory/OrderHistory";
import { NewAddress } from "./Pages/Checkout/NewAddress";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

function App() {
  const history = createBrowserHistory();

  // localStorage.setItem("name", name);

  return (
    <Router history={history}>
      {/* <BrowserView> */}
        <div className="root">
          <Header />
          <Options />

          <div className="body">
            {/* <Breadcrumb /> */}
            <Switch>
              <Route path="/" exact={true} component={HomeScreen} />

              <Route path="/subCategories/:id" component={SubCategories} />

              <Route path="/ProductList/:id" component={ProductList} />

              <Route path="/SingleProduct/:id" component={SingleProduct} />

              <Route path="/cart" component={CartPage} />

              <Route path="/checkout" component={Checkout} />

              <Route path="/NewAddress" component={NewAddress} />

              <Route path="/usersign" component={SignIn} />

              <Route path="/usersignup" component={SignUp} />

              <Route path="/CardDetails" component={CardDetails} />

              <Route path="/OrderHistory" component={OrderHistory} />
              {/* <Route path='*' exact={true} component={NotFound} /> */}

              <Route component={NotFound} />
            </Switch>
          </div>

          <Footer />
        </div>
      {/* </BrowserView> */}
     
    </Router>
  );
}

export default App;
