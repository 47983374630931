import React, { useState } from "react";
import "./SignIn.css";

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div class="user-ragistration">
      <div class=" register">
        <div class="row">
          <div class="col-md-3 register-left">
            <img src="" alt="" />
            <div className="NewHere">
              <h2>New to Blitz?</h2>
              <p>
                <a href="/usersignup">Create your new account</a>
              </p>
            </div>
            <br />
          </div>

          <div class="col-md-9 register-right">
            <div class="tab-content" id="myTabContent">
              <hr></hr>
              <div>
                <h2 className="SignInHeading">SIGN IN</h2>
              </div>
              <hr></hr>
              <div className="SignIn-form">
                <form onSubmit={submitHandler}>
                  <ul className="form-container">
                  
              <div  className="SignInHeadingMob">
              <hr></hr>
                <h2 className="SignInHeading">SIGN IN</h2>
                <hr></hr>
              </div>
          
                    <li></li>
                    <li>
                      {/* <div>Loading...</div>
    //       <div>error</div> */}
                    </li>
                    <li>
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                    </li>
                    <li>
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                      ></input>
                    </li>
                    <li>
                      <button
                        type="submit"
                        className="button primary btn btn-primary  my-4 p bold  "
                      >
                        Sign In
                      </button>

                      <p>
                        <a href="#">Forgot Password</a>
                      </p>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};
