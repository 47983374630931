import React, { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import CreditCardIcon from "@material-ui/icons/CreditCard";

import "./Checkout.css";
import { PaymentMethods } from "./PaymentMethods";

export const Checkout = () => {
  const [isAddressSelected, setisAddressSelected] = useState(false);

  const handleAddress = (id) => {
    setisAddressSelected(!isAddressSelected);
    // window.screenTop;
  };

  return (
    <div className="Container">
        <hr></hr>
      <div><h2 className="CheckoutHeading">Complete Your Order</h2></div>
      <hr></hr>
      <div className="Header_checkout">     
      </div>

      <div class="wrappereds">
        {isAddressSelected ? (
          <div className="SelectedPreviousAddresDiv">
            
            <div className="SelectedPreviousAddress">
              <form action="#PaymentMethods">
                <p style={{ color: "black", fontSize: "16px" }}>
                  <strong>Name Three</strong>
                  <p>
                    Flat No. 32/B
                    <br></br>
                    Ganga Colony
                  </p>
                  Patna 800001
                </p>
                <button class="btn btn-success" onClick={handleAddress} >
                  Change
                </button>
              </form>
            </div>
            <br></br>
            <h2 className="HeaderShip" style={{ fontWeight: "bold", padding: "5px 0px" }}>
             <span> Will be Shipped To:</span>
            </h2>
          </div>
        ) : (
          <div>
            <h2 className="HeaderShip"  style={{ fontWeight: "bold", padding: "5px 0px" }}>
             <span>SELECT DELIVERY ADDRESS</span> 
            </h2>
            <div className="PreviousAddress">
              <div className="PreviousAddres">
                <form action="#">
                  <p style={{ color: "black", fontSize: "16px" }}>
                    <strong>Name One</strong>
                    <p>
                      Flat No. 32/B
                      <br></br>
                      Ganga Colony
                    </p>
                    Patna 800011
                  </p>
                  <button className="btn btn-success" onClick={handleAddress}>Use This Address</button>
                </form>
              </div>
              <div className="PreviousAddres">
                <form action="#">
                  <p style={{ color: "black", fontSize: "16px" }}>
                    <strong>Name One</strong>
                    <p>
                      Flat No. 32/B
                      <br></br>
                      Ganga Colony
                    </p>
                    Patna 800011
                  </p>
                  <button className="btn btn-success" onClick={handleAddress}>Use This Address</button>
                </form>
              </div>
              <div className="PreviousAddres">
                <form action="#">
                  <p style={{ color: "black", fontSize: "16px" }}>
                    <strong>Name One</strong>
                    <p>
                      Flat No. 32/B
                      <br></br>
                      Ganga Colony
                    </p>
                    Patna 800011
                  </p>
                  <button className="btn btn-success" onClick={handleAddress}>Use This Address</button>
                </form>
              </div>
              <div className="PreviousAddres">
                <form action="#">
                  <p style={{ color: "black", fontSize: "16px" }}>
                    <strong>Name One</strong>
                    <p>
                      Flat No. 32/B
                      <br></br>
                      Ganga Colony
                    </p>
                    Patna 800011
                  </p>
                  <button className="btn btn-success" onClick={handleAddress}>Use This Address</button>
                </form>
              </div>
              <div className="PreviousAddres">
                <form action="#">
                  <p style={{ color: "black", fontSize: "16px" }}>
                    <strong>Name One</strong>
                    <p>
                      Flat No. 32/B
                      <br></br>
                      Ganga Colony
                    </p>
                    Patna 800011
                  </p>
                  <button className="btn btn-success" onClick={handleAddress}>Use This Address</button>
                </form>
              </div>
              <div className="PreviousAddres">
                <form action="#">
                  <p style={{ color: "black", fontSize: "16px" }}>
                    <strong>Name One</strong>
                    <p>
                      Flat No. 32/B
                      <br></br>
                      Ganga Colony
                    </p>
                    Patna 800011
                  </p>
                  <button className="btn btn-success" onClick={handleAddress}>Use This Address</button>
                </form>
              </div>

              <div className="PreviousAddres">
                <form action="#PaymentMethods">
                  <p style={{ color: "black", fontSize: "16px" }}>
                    <strong>Name Three</strong>
                    <p>
                      Flat No. 32/B
                      <br></br>
                      Ganga Colony
                    </p>
                    Patna 800001
                  </p>
                  <button class="btn btn-success" onClick={handleAddress} >
                    Use This Address
                  </button>
                </form>
              </div>
            </div>

            <br></br>
            <div className="NewAddress">
              <h2 className="HeaderNewShip"  style={{  fontWeight: "bold", padding: "5px 0px" }}>
             <span>SHIP TO NEW ADDRESS?</span> 
            </h2>
              <button
                type="submit"
                className="btn btn-secondary NewAddressbtn bold  "
              >
                ADD NEW ADDRESS
              </button>
            </div>
          </div>
        )}

        <div className="col-6" id="PaymentMethods">
          {" "}
          <PaymentMethods />
        </div>

        {/* </div> */}
      </div>
    </div>
  );
};
