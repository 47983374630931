import React,{ useState} from "react";

import pImg1 from "../../assets/ProductList_two/1.jpg";
import loadingImg from '../../assets/loading.gif'

import StarRating from "./StarRating";
import { StarRatingLoop } from "./StarRatingLoop";


export const ProductLoop = ({ Products, loading, allProducts }) => {
    
  const [ star , setStar] = useState(4);
  //console.log("the data", Products)
  // const [ SortType, setSortType] = useState('asc');


  // const sorted = allProducts.sort(( a, b) => {

  //   const isReversed = ( SortType === 'desc') ? 1 : -1;
  //   return isReversed * a.title.localeCompare(b.title)

  // })  

  if (loading) {
    return <h3>Loading...<img src={loadingImg} alt="" / ></h3>;
  }

  return (
    <div >
         <div className="row align-items-start" >
         
      {Products.map((post, i) => {

            console.log("pr" , post)

     

      return <div key={i}>

          <div class="col-md-3 mb-3 product-item " >
            <div class="card h-100">
              <div class="labels d-flex justify-content-between position-absolute w-100">
                <div class="label-new">
                  <span class="text-white bg-success small d-flex align-items-center px-2 py-1">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <span class="ml-2">New</span>
                  </span>
                </div>
                <div class="label-sale">
                  <span class="text-white bg-primary small d-flex align-items-center px-2 py-1">
                    <i class="fa fa-tag" aria-hidden="true"></i>
                    <span class="ml-1">Sale</span>
                  </span>
                </div>
              </div>
              <a href={`/SingleProduct/${post.productId}`}>
               
                  <div>
                 
                  
                      <img src={pImg1} class="card-img-top" alt="Product" />
                   
                  </div>
              
              </a>
              <div class="card-body px-2 pb-2 pt-1">
                <div class="d-flex justify-content-between">
                  <div>
                  <p class="h4 text-primary">  ₹  {post.partNumber}</p>
                  </div>
                  <div>
                    <a
                      href={`/electronics/${post.categoryId}`}
                      class="text-secondary lead"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="Compare"
                    >
                      <i class="fa fa-icon" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <p className="product_title" style={{height:"40px"}}>
                  <strong>
                    <a href="#" class="text-secondary">
                      {post.name}
                    </a>
                  </strong>
                </p>
                <p class="mb-1">
                  <small>
                    <a href="#" class="text-secondary">
                      Brands
                    </a>
                  </small>
                </p>
                <div class="d-flex mb-3 justify-content-between">
                  <div></div>
                  <div class="text-right">
                    <p class="mb-0 small">
                      <b>Free Shipping</b>
                    </p>

                    <br></br>

                    <p class="mb-0 small text-primary">
                      <span class="font-weight-bolder">Save</span> Rs. 120.00
                      (16%)
                    </p>
                  </div>
                  <p class="text-warning d-flex align-items-center mb-2">
                  <StarRating star={star} />
                  </p>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="col px-0">
                    <button class="btn btn-outline-primary btn-block">
                      Add To Cart
                      <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="ml-2">
                    <a
                      href="#"
                      class="btn btn-outline-success"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="Add to Wishlist"
                    >
                      <i class="fa fa-heart" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      }
      )}
    </div> </div>
  );
};
