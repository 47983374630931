import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


import '../../assets/css/main.css'


function valuetext(value) {
    return `${value}`;
}

export default function LeftPanal() {

    const [value, setValue] = useState([20, 37]);

    const [loading, setLoading] = useState(false);
    const [Category, setCategory] = useState([]);

    const handleChange = (event, newValue) => {
        
        setValue(newValue);

     const differnce = value[1] - value[0];
     console.log(differnce)
    };


    useEffect(() => {
        const fetchPosts = async () => {
          setLoading(true);
          await axios({
            method: "get",
            url: "http://54.167.190.182:8097/retail/categories/fetchAllCategories",
            headers: {},
          })
            .then(function (response) {
              console.log(
                "Got response",
                response.data.result.categoryList[0].category_name
              );
              setCategory(response.data.result.categoryList);
            })
            .catch(function (response) {
              console.log(response);
            });
          setLoading(false);
        };
    
        fetchPosts();
      }, []);

    return (
        <section>
         <div class="SubCatcontainer">
        <div class="row md-12">
          <div class="col-md-12">
            <div class="card mb-1">
              <div class="card-header">Categories</div>
              {Category.map((value, i) => {
                // console.log("From return state", value);

                if (value.length < 0) {
                  return (
                    <div>
                      Loading
                      {/* <img src={home} alt="h" /> */}
                    </div>
                  );
                } else if (value.category_parent_id === 0) {
                  return (
                    <div key={i}>
                      <div class="list-group list-group-flush">
                        <a
                        href={`/subCategories/${value.category_id}`}
                          class="list-group-item list-group-item-action"
                        >
                          {value.category_name} 
                        </a>
                        
                      </div>
                    </div>
                  );
                }
              })}
              </div>
            </div>
          </div>
        </div>
        </section>

    )
}