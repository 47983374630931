import React from "react";

import CreditCardIcon from "@material-ui/icons/CreditCard";
import upi from "../../assets/Payment/upi.png";
import card from "../../assets/Payment/card.png";
import other from "../../assets/Payment/other.png";

export const PaymentMethods = () => {
  return (
    <div class=" order">
      <h3 class="topborder">
        <hr></hr>
        <h2>Detailed Bill</h2>
        <hr></hr>
      </h3>
      <div class="row">
        <h4 class="inline">Products</h4>
      </div>
      <div>
        <p class="prod-description inline">
          Nice Dress
          <div class="qty inline">
            <span class="smalltxt">x</span> 1
          </div>
        </p>

        <p class="alignright center bold productPrice"> ₹ 1199</p>
      </div>
      <div>
        <p class="prod-description inline">
          Oppo SmartPhone
          <div class="qty inline">
            <span class="smalltxt">x</span> 1
          </div>
        </p>

        <p class="alignright center bold"> ₹ 26191</p>
      </div>
      <div>
        <h5>Cart Subtotal</h5>
        <p class="alignright center bold cartTotal"> ₹ 26191</p>
      </div>
      <div>
        <h5 class="inline difwidth">Shipping and Handling</h5>
        <p class="inline alignright center">Free Shipping</p>
      </div>
      <div className="cartTotals">
        <h5>Order Total</h5>

        <p class="alignright center bold cartTotal"> ₹ 26191</p>
      </div>

<div className="PaymentMethods">
      <div>
        <h3 class="topborder">
          <hr></hr>
          <h2>Select Payment Method</h2>
          <hr></hr>
        </h3>

        <p>
          <img src={card} alt="card" height="50px" /> &nbsp;Card Payment
        </p>

        <span>
          <p class="padleft">
            <input
              type="radio"
              value="Debit Card"
              name="payment"
              className="PInput"
            />
            Debit Card
          </p>
        </span>
        <p class="padleft">
          
          <input
            type="radio"
            value="Debit Card"
            name="payment"
            className="PInput"
          />
          Credit Card
        </p>

        <span>
          <p class="padleft">
            <input
              type="radio"
              value="Debit Card"
              name="payment"
              className="PInput"
            />
            Rupay Card
          </p>
        </span>
      </div>

      <div>
        <p>
          <img src={upi} alt="upi" /> &nbsp; UPI Payment
        </p>
        <span>
          <p class="padleft">
            <input
              type="radio"
              value="Debit Card"
              name="payment"
              className="PInput"
            />
            BHIM PAYMENT
          </p>
        </span>
      </div>
      <div>
        <p>
          <img src={other} alt="upi" height="35px" /> &nbsp;Other Payments
        </p>
        <span>
          <p class="padleft">
            <input
              type="radio"
              value="Debit Card"
              name="payment"
              className="PInput"
            />
            American Express
          </p>
          <p class="padleft">
            <input
              type="radio"
              value="Debit Card"
              name="payment"
              className="PInput"
            />
            Paypal
          </p>
        </span>
        <fieldset class="paymenttypes">
          <legend>
            <img
              src="https://www.paypalobjects.com/webstatic/icon/pp258.png"
              alt="PayPal Logo"
              class="paypal"
              height="10px"
            />
          </legend>
          <img
            src="https://support.moqups.com/hc/article_attachments/115010765209/02._Your_Account_-_Logos_.jpg"
            alt="Visa, Mastercard, Maestro and Amex Credit Cards"
            class="cards"
            height="30px"
          />
        </fieldset>

        <a href="#" class="padleft">
          What is Paypal?
        </a>
      </div>
      <a href="/CardDetails">
        {/* <input
          type="submit"
          name="submit"
          value="Make Payment"
          class="redbutton"
        />
      </a> */}
      
        <button
          type="submit"
          className=" redbutton btn btn-primary   "
          
        >
          <i class="fa fa-lock"></i> Pay Securely
        </button>
      </a>
    </div>
    </div>
  );
};
