import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import "./style/GallaryView.css";
import VideoView from "./VideoView";

import ModalVideo from "react-modal-video";

import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import playImg from '../../assets/ProductDesc/playImg.png'

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

class GallaryView extends Component {
  constructor() {
    super();
    this.state = {
      thumbs: String,
      isOpen: false,
    };
  }

  //   const slides = [];
  //   for (let i = 0; i < 5; i += 1) {
  //     slides.push(
  //       <SwiperSlide key={`slide-${i}`} tag="li">
  //         <img
  //           src={`https://picsum.photos/id/${i + 1}/500/300`}
  //           style={{ listStyle: 'none' }}
  //           alt={`Slide ${i}`}
  //         />
  //       </SwiperSlide>
  //     );
  //   }

  a(val) {
    console.log(val);
  }

  myEnterFunction() {
    console.log("enter mouse");
  }

  componentWillMount() {
    // console.log(this.props)

    var thumbs = [];
    this.setState({ thumbs: thumbs });

    console.log("thumbs", thumbs);

    if (this.props.allImages) {
      this.props.allImages.map((val, i) => {
        console.log(val, i);

        // if(val ===)
        // this.props.allVideos.map((valV, i) => {
        //     console.log(valV)

        return thumbs.push(
          <div>
            <SwiperSlide tag="li" style={{ listStyle: "none" }}>
              <img
                key={i}
                width="100"
                src={val}
                alt={"sa"}
                onClick={this.props.currentImg.bind(this, val)}
              />


                
              {/* <img
              key={i}
                className="PlayImg"
                width="100"
                src="https://img.youtube.com/vi/03isxzvEEjw/0.jpg"
                alt={"sa"}
                onClick={() => this.setState({ isOpen: true })}
              /> */}
              {/* <img className="btn" src={playImg} height="10px" width="10px"/> */}
              
              {/* <iframe width="1216" height="684" src="https://www.youtube.com/embed/XXYlFuWEuKI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              {/* 
                        <iframe width="100" src={val} onClick={
                            this.props.currentImg.bind(
                                this,
                                val
                            )
                        }
                            title="link" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

              {/* <iframe allowfullscreen="" frameborder="0" height="315" src="" width="560"></iframe> */}

              {/* <div id="slider" class="flexslider">
<ul class="slides">
<li><img  width="100"

src={val}
alt={"sa"} /></li>
<li><video  controls preload="none" width="100%" height="100%" poster="https://archive.org/download/14405804O1_201507/14405804_o1.jpg" >
<source src="https://archive.org/download/CEP268/CEP268_512kb.mp4" type='video/mp4' />
</video></li>
</ul>
</div> */}
            </SwiperSlide>
           
          </div>
        );
      });

      // })
    }
  }

  //   const slides2 = [];
  //   for (let i = 9; i < 14; i += 1) {
  //     slides2.push(
  //       <SwiperSlide key={`slide-${i}`} tag="li">
  //         <img
  //           src={`https://picsum.photos/id/${i + 1}/500/300`}
  //           style={{ listStyle: 'none' }}
  //           alt={`Slide ${i}`}
  //         />
  //       </SwiperSlide>
  //     );
  //   }
  render() {
    return (
      <React.Fragment>
        <Swiper
          id="thumbs"
          spaceBetween={2}
          slidesPerView={4}
          navigation
          scrollbar={{ draggable: true }}
        >
          {this.state.thumbs}
        </Swiper>

      
      </React.Fragment>
    );
  }
}

export default GallaryView;
