import React, { useEffect, useState } from "react";
import axios from "axios";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import OptionsSlider from './OptionsSlider.js'

import appliance from "../../src/assets/applicence.PNG";
import allCAt from "../../src/assets/Mobile/allCat.png";
// import fashion from "../../src/assets/fashion.PNG";
// import grocery from "../../src/assets/grocery.PNG";
// import loading from "../../src/assets/loading.gif";
// import mobiles from "../../src/assets/mobiles.PNG";
// import travel from "../../src/assets/travel.PNG";
// import offers from "../../src/assets/offers.PNG";

import "./Options.css";

export default function Options() {
  const [loading, setLoading] = useState(false);
  const [Category, setCategory] = useState([]);
  // const [sForwardId, setForwardId] = useState([]);
  const [slidesToShow, setslidesToShow] = useState(Number);

  const handleWidth = () => {
    // const PosterWidth = window.innerWidth;
    // setWidth(PosterWidth);
    // const PosterHeight = window.innerHeight;
    // setHeight(PosterHeight);

    if (window.innerWidth < 501) {
      setslidesToShow(2);
    } else if (window.innerWidth > 501 && window.innerWidth < 801) {
      setslidesToShow(4);
    } else if (window.innerWidth > 801 && window.innerWidth < 1700) {
      setslidesToShow(6);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWidth, { passive: true });

   
    if (window.innerWidth < 501) {
      setslidesToShow(2);
    } else if (window.innerWidth > 501 && window.innerWidth < 801) {
      setslidesToShow(4);
    } else if (window.innerWidth > 801 && window.innerWidth < 1700) {
      setslidesToShow(6);
    }
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      await axios({
        method: "get",
        url: "http://54.167.190.182:8097/retail/categories/fetchAllCategories",
        headers: {},
      })
        .then(function (response) {
          console.log("Got response", response.data.result.categoryList);
          setCategory(response.data.result.categoryList);
        })
        .catch(function (response) {
          console.log(response);
        });
      setLoading(false);
    };

    fetchPosts();
  }, []);

  return (
    <div className="OptionsClass">
      <div className="MobileOptionsClass">
        
        <div className="allCAt">
        <OptionsSlider />
          {/* <img src={allCAt} alt="allCAt" height="45px" />
          <div className="allCAtName">
            <span className="allCAtName">View All</span>
            <span className="allCAtName2"> Categories</span>
          </div> */}
        </div>

        <div className="TopCat">
          <span className="topCat">Top Categories</span>
          <ul class="TopCatMenu">
            <div className="TopCatImgDiv">
              <a href={`/subCategories/`}>
                <img src={appliance} className="TopCatImg" alt="i" />

                <p className="TopCatTitle">Cat One </p>
              </a>
            </div>
            <div className="TopCatImgDiv">
              <a href={`/subCategories/`}>
                <img src={appliance} className="TopCatImg" alt="i" />

                <p className="TopCatTitle">Cat Two </p>
              </a>
            </div>
            <div className="TopCatImgDiv">
              <a href={`/subCategories/`}>
                <img src={appliance} className="TopCatImg" alt="i" />

                <p className="TopCatTitle">Cat Three </p>
              </a>
            </div>
            <div className="TopCatImgDiv">
              <a href={`/subCategories/`}>
                <img src={appliance} className="TopCatImg" alt="i" />

                <p className="TopCatTitle">Cat Four </p>
              </a>
            </div>
          </ul>
        </div>
      </div>

      <div className="SliderImg hover12 column ">
        <Slider {...settings} style={{ Width: "100%" }}>
          {/* <a href="/subCategories/1" > <h3>Local Test</h3></a>  */}
          {/* // eslint-disable-next-line array-callback-return */}
          {Category.map((value, i) => {
            // console.log("From return state", value);

            if (value.length < 0) {
              return (
                <div>
                  Loading
                  <img src={loading} alt="h" />
                </div>
              );
            } else if (value.category_parent_id === 0) {
              return (
                <div key={i}>
                  <div class="dropdown  mp-box-whit mp-cat-list-curated js-cat-list-curated">
                    <div class="box-row">
                      <div>
                        <ul class="main-cat-list js-main-cat-list cf active">
                          <a href={`/subCategories/${value.category_id}`}>
                            <img
                              src={appliance}
                              className="dropImg Headimg"
                              alt={value.category_description}
                            />

                            <p className="title_p">{value.category_name} </p>
                          </a>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </Slider>
      </div>
    </div>
    // </div>
  );
}
