import React from "react";

export const NewAddress = () => {
  return (
    <div className="Container">
      <div className="Header_checkout">
        <hr></hr>
        <h2 class="HeaderNewAddress">ADD NEW ADDRESS</h2>
        <hr></hr>
      </div>

      <div class="wrappered">
        <form method="get" className="allModules">
          <div class="col-12 col">
            <h2
              className="HeaderNewAddShip"
              style={{ fontWeight: "bold", padding: "5px 0px" }}
            >
              <span>Shipping/Billing Address</span>
            </h2>

            <br></br>
            <label for="country">Country</label>
            <select name="country" id="country" required>
              <option value="">Please select a country</option>
              <option value="Canada">India</option>
              <option value="Not Canada">Not India</option>
            </select>
            <div class="width50 padright">
              <label for="fname">First Name</label>
              <input type="text" name="fname" id="fname" required />
              <label for="lname">Last Name</label>
              <input type="text" name="lname" id="lname" required />
            </div>

            <div class="width50 padright">
              <label for="company">Address Line 1:</label>
              <input type="text" name="company" id="company" required />
              <label for="address">Address Line 2:</label>
              <input type="text" name="address" id="address" required />
              <input
                type="text"
                name="address"
                id="address2"
                placeholder="Land Mark  "
              />
              <label for="city">Town / City</label>
              <input type="text" name="city" id="city" required />
              <div class=" padright width100">
                <label for="province">State</label>
                <select name="province" id="province" required>
                  <option value="">Please select a State</option>
                  <option value="ab">A</option>
                  <option value="bc">B</option>
                  <option value="mb">C</option>
                  <option value="nb">D</option>
                  <option value="on">E</option>
                  <option value="qc">F</option>
                </select>
              </div>
              <div class="width60">
                <label for="postcode">Postcode</label>
                <input
                  type="text"
                  name="postcode"
                  id="postcode"
                  placeholder="Postcode / Zip"
                  required
                />
                <label for="tel">Contact Number</label>
                <input type="text" name="tel" id="tel" required />
              </div>
            </div>
            <div class="width50"></div>
            <div>
              <input
                type="submit"
                className=" redbutton SubmitAddressBtn btn btn-primary"
                name="submit"
              />

              {/* </a> */}
            </div>
          </div>

          {/* 
              <div className="Pmethods"> */}

          {/* </div> */}
        </form>
      </div>
    </div>
  );
};
