import React, { Component } from "react";
import "./Footer.css";

import foot from "../../assets/foot.PNG";
import mainLogo from "../../assets/company_logo.png";

class Footer extends Component {
  render() {
    return (
      <div>
        <div className=" v">
          {/* <img className="foot_img" src={foot} alt="footer" /> */}
        </div>
        <section id="footer">
          <div class="container footerContainer">
            <div class="row text-center text-xs-center text-sm-left text-md-left">
              <div class="col-xs-12 col-sm-12 col-md-4">
                <h5>Quick links</h5>
                <ul class="list-styled quick-links">
                  <li>
                    <a href="/">
                      <i class="fa fa-angle-double-right"></i>Home
                    </a>
                  </li>
                  <li>
                    <a href="/cart">
                      <i class="fa fa-angle-double-right"></i>Cart
                    </a>
                  </li>
                  <li>
                    <a href="/checkout">
                      <i class="fa fa-angle-double-right"></i>Checkout
                    </a>
                  </li>
                  <li>
                    <a href="/NewAddress">
                      <i class="fa fa-angle-double-right"></i>New Address
                    </a>
                  </li>
                  <li>
                    <a href="/OrderHistory">
                      <i class="fa fa-angle-double-right"></i>Orders
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <h5>Top Catergoires</h5>
                <ul class="list-styled quick-links">
                  <li>
                    <a href="#">
                      <i class="fa fa-angle-double-right"></i>Clothing
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-angle-double-right"></i>Mobiles
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-angle-double-right"></i>Appliance
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-angle-double-right"></i>Fooding
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-angle-double-right"></i>Assessories
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                <ul class="list-unstyled list-inline social text-center">
                  <li class="list-inline-item">
                    <a href="#">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      <i class="fa fa-google-plus"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#" target="_blank">
                      <i class="fa fa-envelope"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <hr></hr>
            </div>

            {/* <div className="col-xs-12  col-md-3" style={{color:"white"}}>
              Contact Us:
                  <br></br>
              <p>
                Head Office
                <span>Address1 </span>
              </p>
               
            </div> */}

            {/* <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
            <p><u><a href="https://www.nationaltransaction.com/">National Transaction Corporation</a></u> is a Registered MSP/ISO of Elavon, Inc. Georgia [a wholly owned subsidiary of U.S. Bancorp, Minneapolis, MN]</p>
            <p class="h6">© All right Reversed.<a class="text-green ml-2" href="https://www.sunlimetech.com" target="_blank">Sunlimetech</a></p>
          </div>
          <hr></hr>
        </div>	 */}
          </div>
        </section>
        <div
          className="col-md-1 "
          className="mainLogoFooter"
          style={{ color: "white" }}
        >
          <a href="#">
            <img src={mainLogo} height="80px" className="mainLogoFooter"></img>
          </a>
        </div>
        <div className="footer">
          &copy; All rights reserved | Retail Blitz | 2021
        </div>
      </div>
    );
  }
}

export default Footer;
