import React from "react";

import "./SignUp.css";

export const SignUp = () => {
  return (
    <div class="user-ragistration">
      <div class=" register">
        <div class="row">
          <div class="col-md-3 register-left">
            <img src="" alt="" />

            <div className="NewHere">
              <h2>Already Regiserted?</h2>
              <p>
                <a href="/usersign">Login Here</a>
              </p>
            </div>
            <br />
          </div>
          <div class="col-md-9 register-right">
          <hr></hr>
              <div className="SignUpHeading">
                <h2 className="SignUpHeadingText">REGISTER NEW ACCOUNT</h2>
              </div>
              <hr></hr>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <h2 class="register-heading"></h2>
                
                <div class="row register-form">
             
                  <div class="col-md-10">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="First Name *"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Last Name *"
                        value=""
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Password *"
                        value=""
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Confirm Password *"
                        value=""
                      />
                    </div>
                    <div class="form-group">
                      
                        <p class="Gender_radio">
                          <input
                            type="radio"
                            name="gender"
                            value="male"
                            checked
                          />
                          <span> Male </span>
                        </p>

                        <p class="Gender_radio">
                          <input type="radio" name="gender" value="female" />
                          <span>Female </span>
                        </p>
                      
                    </div>
                  </div>
                  <div class="col-md-10">
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Your Email *"
                        value=""
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        minlength="10"
                        maxlength="10"
                        name="txtEmpPhone"
                        class="form-control"
                        placeholder="Your Phone *"
                        value=""
                      />
                    </div>
                    <div class="form-group">
                      <select class="form-control">
                        <option class="hidden" selected disabled>
                          Please select your Sequrity Question
                        </option>
                        <option>What is your Birthdate?</option>
                        <option>What is Your old Phone Number</option>
                        <option>What is your Pet Name?</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Your Answer *"
                        value=""
                      />
                    </div>
                    <button
                      type="submit"
                      className="button primary btn btn-primary  my-4 p bold  "
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
