import React from "react";
import "./style/Offers.css";

import Offer_icon from '../../assets/ProductDesc/offer_icon.png'


export default function Offers() {
  return (
  <div className="OffersContainer">
<hr></hr>
      <table className="OfferTable">
  <tr>
    <th><i class="fab fa-react"></i><img src={Offer_icon} alt="Offer_icon" height="50px" />OFFERS</th>

  </tr>
  <tr >
    <th className="OfferHeading">Exchange Offer</th>
    <td>Exchange Upto 30% OFF</td>
  </tr>
  <tr>
    <th className="OfferHeading"> Cashback</th>
    <td>Get 50% Cashback, upto ₹300</td>
  </tr>

  <tr>
    <th className="OfferHeading"> Bank Offer</th>
    <td>Get 50% Cashback, upto ₹300</td>
  </tr>

  <tr>
    <th className="OfferHeading"> Coupans</th>
    <td>Get 50% Cashback, upto ₹300</td>
  </tr>

</table>

  </div>

  );
}
