import React, { useEffect, useState } from "react";

import LeftPanal from "./LeftPanel";
import StarRating from "./StarRating";
import "./ProductList.css";
import { useParams } from 'react-router';
import pImg1 from "../../assets/ProductList_two/1.jpg";
import pImg2 from "../../assets/ProductList_two/2.jpg";
import pImg3 from "../../assets/ProductList_two/3.jpg";
import pImg4 from "../../assets/ProductList_two/4.JPG";

import PaginationCount from "./PaginationCount";
import axios from "axios";
import { ProductLoop } from "./ProductLoop";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import ImportExportIcon from "@material-ui/icons/ImportExport";
import { CartPage } from "../CartPage/CartPage";


export default function ProductList(props) {
  const [Products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ProductsPerPage] = useState(5);

  const [SortType, setSortType] = useState("desc");
  const [NumberSortType, setNumberSortType] = useState("NumDesc");

  const [test, setTest] = useState([]);

  const { id } = useParams();
// console.log(id)


  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const data = {
        "searchMap":
         { "category": [`${id}`]
         }
      };
       await axios({
        method: "post",
        url: `http://3.90.53.217:8090/demo/product/search`,
        data: data,
      
       
      })
      .then(function (response) {
        console.log("Got response", response.data.result);

       setProducts(response.data.result.productList)
      //  console.log("Products", Products)
      })
      .catch(function (response) {
        console.log("error",response);
      });
  
      // console.log("demo res", res.result)
      // setProducts(res.result);

      setLoading(false);
    };

    fetchPosts();
  }, []);


 
  //  //Sorting By Name
  //  const sorted = Products.sort((a, b) => {
  //   const isReversedNum = SortType === "asc" ? 1 : -1;
  //   return isReversedNum * a.title.localeCompare(b.title);
  // });

  
 //Sorting By Price
 const sortNum = Products.sort((a, b) => {
  const isReversed = NumberSortType === "NumAsc" ? b.partNumber - a.partNumber : a.partNumber - b.partNumber;
  return isReversed;
});

  // console.log(posts)

  // Get Current Post
  const indexOfLastProducts = currentPage * ProductsPerPage;
  const indexOfFirstProducts = indexOfLastProducts - ProductsPerPage;
  const currentProducts = sortNum.slice(
    indexOfFirstProducts,
    indexOfLastProducts
  );

 const HighToLow = () => {
    setNumberSortType("NumAsc");
    setCurrentPage(1)
  }
  const LowToHigh = () => {
    setNumberSortType("NumDesc");
    setCurrentPage(1)
  }
  //Change Page
  const Paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div class="containerd">
        <div className="leftPanel">
          <div>
            <LeftPanal />
          </div>
        </div>

        <div className="products-container">
          <div className="pagination-count">
            <PaginationCount
              ProductsPerPage={ProductsPerPage}
              totalProducts={Products.length}
              Paginate={Paginate}
              currentPage={currentPage}
            />
          </div>
          <div className="currentProducts">
            <ProductLoop
              allProducts={Products}
              Products={currentProducts}
              loading={loading}
            />
          </div>

          <div className="SortingBlock">
            <div className="SortingBlock">
              <p style={{ color: "black", marginTop: 10 }}>
                <ImportExportIcon />
                SORT PRICE:
              </p>
              <ButtonGroup
                variant="text"
                color="secondary"
                aria-label="text primary button group"
              >
                <Button onClick={HighToLow} >
                  High To Low
                </Button>
                <Button onClick={LowToHigh}>
                  Low To High
                </Button>
              </ButtonGroup>
            </div>
            <br></br>
            {/* <div className="SortingBlock">
              <p style={{ color: "black", marginTop: 10 }}>
                <ImportExportIcon />
                SORT BY NAME:
              </p>
              <ButtonGroup
                variant="text"
                color="secondary"
                aria-label="text primary button group"
              >
                <Button onClick={() => setSortType("asc")}>A to Z</Button>
                <Button onClick={() => setSortType("desc")}>Z to A</Button>
              </ButtonGroup>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
