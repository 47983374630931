import React, { Component } from "react";

import "./style/PinCode.css";

import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";

import GpsFixedRoundedIcon from "@material-ui/icons/GpsFixedRounded";

class PinCode extends Component {
  constructor() {
    super();
    this.myRef = React.createRef();
    this.state = {
      PinCodeMatch: false,
      CheckPin: Number,
      NotMatchBoolean: false,
      NotMatchString: "Pin Code Length Should Be Of 6 Numbers",
      MatchDatabasePinCode: ["801901", "910314", "800011"],
    };
  }

  PinChange = (e) => {
    //console.log('Pin', e.target.value)
    if (e.target.value !== "") {
      this.setState({ CheckPin: e.target.value });
      // console.log(this.state.CheckPin)
      if (e.target.value.length < 6) {
        this.setState({ NotMatchBoolean: true });
      } else if (e.target.value.length === 6) {
        this.setState({ NotMatchBoolean: false });
      } else {
        this.setState({ NotMatchBoolean: true });
      }
    }
  };

  ReCheckPin = () => {
    this.setState({ PinCodeMatch: false });
  };

  checkPin = (e) => {
    e.preventDefault();
    //console.log(this.state.MatchDatabasePinCode)

    if (this.state.CheckPin !== "") {
      this.state.MatchDatabasePinCode.forEach((val, i) => {
        // console.log(val)
        // console.log(this.state.CheckPin);
        if (this.state.CheckPin === val) {
          console.log("deliverdable");
          this.setState({ PinCodeMatch: true });
        }
      });
    }
  };

  render() {
    return (
      <div>
        {this.state.PinCodeMatch ? (
          <div className="CheckPinTrue">
            <p style={{ color: "green", fontSize: "20px" }}>
              Deliverable at {this.state.CheckPin}
              <button
                style={{ color: "red", border: "white" }}
                onClick={this.ReCheckPin}
              >
                <CloseIcon fontSize="large" />
              </button>
            </p>
          </div>
        ) : (
          <div className="CheckPinFalse">
            <form
              onSubmit={this.checkPin}
              className="CheckPin"
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  type="number"
                  id="standard-basic"
                  maxlength="4"
                  size="4"
                  checkPin={this.checkPin.bind(this)}
                  onChange={this.PinChange}
                  label="Pin-Code Delivery"
                  placeholder="100011"
                  style={{
                    width: "150px",
                    fontSize: "15px",
                  }}
                />
              </div>
              {document.getElementById("ndev")}
              {}

              <button className="CheckPinBtn" style={{ border: "white" }}>
                {/* <CheckCircleTwoToneIcon fontSize="large"  style={{ color: "green" }}/>
                 */}
                <GpsFixedRoundedIcon
                  fontSize="large"
                  style={{ color: "green" }}
                />
              </button>
            </form>
          </div>
        )}

        <p style={{ color: "red", fontWeight: "bold" }}>
          {" "}
          {this.state.NotMatchBoolean ? this.state.NotMatchString : null}
        </p>
      </div>
    );
  }
}

export default PinCode;
