import React from "react";
import Pagination from "@material-ui/lab/Pagination";

export default function PaginationCount({
  ProductsPerPage,
  totalProducts,
  Paginate,
  currentPage
}) {
  // const [page, setPage] = React.useState(currentPage);
  const handleChange = (event, value) => {
    event.preventDefault();
    // setPage(value);
    Paginate(value);
  };

  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(totalProducts / ProductsPerPage); i++) {
    pageNumber.push(i);
  }

  return (
    <nav>
      <a
        href="#containerd"
        style={{ behavior: "smooth", whiteSpace: "nowrap", overFlow: "scroll" }}
      >
        <Pagination
          count={pageNumber.length}
          size="large"
          color="secondary"
          siblingCount={1}
          boundaryCount={4}
          page={currentPage}
          onChange={handleChange}
        />
      </a>
    </nav>
  );
}
