import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './BrowsingHistory.css';


import img1 from '../../assets/products/1.JPG'
import img2 from '../../assets/products/2.JPG'
import img3 from '../../assets/products/3.JPG'
import img4 from '../../assets/products/4.JPG'
import img5 from '../../assets/products/5.JPG'
import img6 from '../../assets/products/6.JPG'


export default function BrowsingHistory(){
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow:5,
        slidesToScroll: 1
      };
    
    return (

        <div className="myHistorySlider ">
        <p className="HeaderSlider"> Recently Viewed</p>
    <Slider {...settings}>
    <div>
      <img style={{marginBottom: "30px"}} className="productHistoryImg" src={img1} alt="pic2" />
    </div>
    <div>
    <img className="productImg"  src={img2} alt="pic2" />
    </div>
    <div>
    <img className="productImg"  src={img3} alt="pic2" />
    </div>
    <div>
    <img className="productImg"  src={img4} alt="pic2" />
    </div>
    <div>
    <img className="productImg"  src={img5} alt="pic2" />
    </div>
    <div>
    <img className="productImg"  src={img6} alt="pic2" />
    </div>
  </Slider>
  </div>

    )

}