import React from "react";

import "./CardDetails.css";

import Cd from "../../assets/credit-card.png";

class CardDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "First Last",
      Card_number1: "xxxx",
      Card_number2: "xxxx",
      Card_number3: "xxxx",
      Card_number4: "xxxx",
      month: "xx",
      year: "xx",
      ccv: "CCV",
    };
  }

  //Name of Card Owner
  nameChange(n) {
    this.setState({
      name: n.target.value,
    });
  }
  //Card Number
  numberChange1(c) {
    this.setState({
      Card_number1: c.target.value,
    });
  }
  numberChange2(c) {
    this.setState({
      Card_number2: c.target.value, 
    });
  }
  numberChange3(c) {
    this.setState({
      Card_number3: c.target.value,
    });
  }
  numberChange4(c) {
    this.setState({
      Card_number4: c.target.value,
    });
  }
  //Expiration
  monthChange(m) {
    this.setState({
      month: m.target.value,
    });
  }
  dayChange(d) {
    this.setState({
      year: d.target.value,
    });
  }
  //CCV
  ccvChange(v) {
    this.setState({
      ccv: v.target.value,
    });
  }

  render() {
    return (
      <div className="CardDetailsContainer">
        <div className="row">
         
          <br></br>
          <div className="small-5 small-offset-1 columns ">
           <p className="CardAmount">Total Amount to be Paid : &nbsp;<span>₹ 3200 </span> </p>
            <div className="callout credit">
              <div className="row">
                <div className="small-6 columns">
                  <h1 className="credit__bank" style={{ color: "white" }}>
                 <p> Debit Card</p> 
                  </h1>
                </div>
                <div className="small-6 columns">
                  <img className="credit__mc" alt="" />
                </div>
              </div>
              <div className="row" style={{ color: "white" }}>
                <div className="Cardcolumn">
                  <p className="credit__card-number" value="1234">
                    {this.state.Card_number1}
                  </p>
                  &nbsp;&nbsp;&nbsp;
                  <p className="credit__card-number" value="1234">
                    {this.state.Card_number2}
                  </p>&nbsp;&nbsp;&nbsp;
                  <p className="credit__card-number" value="1234">
                    {this.state.Card_number3}
                  </p>&nbsp;&nbsp;&nbsp;
                  <p className="credit__card-number" value="1234">
                    {this.state.Card_number4}
                  </p>
                </div>
                <div className="credit__ccv">
                <span className="credit__ccv">{this.state.ccv}</span>
                </div>
                
                <div className="small-9 columns">
                  <label>
                    Card Holder
                    <p className="credit__name">{this.state.name}</p>
                  </label>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="small-3 columns">
                  <label>
                    Expires
                    <p className="credit__date">
                      {this.state.month} / {this.state.year}
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="columns end CardDetailsForm">
            {/* <h3> Enter Card Details</h3> */}
            <form class="form" autocomplete="off" validate>
              <fieldset className="cardNum">
                <label for="card-nsumber"> Card Number</label>
                <br></br>
                <input
                  returnKeyType="next"
                  autoFocus = {true}
                  type="num"
                  id="card-number"
                  class="input-cart-number"
                  maxlength="4"
                  onChange={this.numberChange1.bind(this)}
                />
                <input
                  type="num"
                  id="card-number-1"
                  class="input-cart-number"
                  maxlength="4"
                  onChange={this.numberChange2.bind(this)}
                />
                <input
                  type="num"
                  id="card-number-2"
                  class="input-cart-number"
                  maxlength="4"
                  onChange={this.numberChange3.bind(this)}
                />
                <input
                  type="num"
                  id="card-number-3"
                  class="input-cart-number"
                  maxlength="4"
                  onChange={this.numberChange4.bind(this)}
                />
              </fieldset>
              <fieldset>
                <label for="card-holder">Card holder</label>
                
                <input
                  type="text"
                  id="card-holder"
                  maxlength="28"
                  onChange={this.nameChange.bind(this)}
                />
              </fieldset>
              <fieldset class="card-expire">
                <label for="expire-month">Expire date</label>
                <div class="select">
                Select Month <select
                    id="expire-month"
                    onChange={this.monthChange.bind(this)}
                  >
                    <option></option>
                    <option>01</option>
                    <option>02</option>
                    <option>03</option>
                    <option>04</option>
                    <option>05</option>
                    <option>06</option>
                    <option>07</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                  </select>
                </div>
                <br></br>
                Select Year  <div class="select">
                  <select id="expire-year" onChange={this.dayChange.bind(this)}>
                    <option></option>
                    <option>2016</option>
                    <option>2017</option>
                    <option>2018</option>
                    <option>2019</option>
                    <option>2020</option>
                    <option>2021</option>
                    <option>2022</option>
                    <option>2023</option>
                    <option>2024</option>
                    <option>2025</option>
                  </select>
                </div>
                <br></br>
              </fieldset>
              <fieldset class="fieldset-ccv">
                <label for="card-ccv">CCV</label>
                <input
                  type="text"
                  id="card-ccv"
                  maxlength="3"
                  onChange={this.ccvChange.bind(this)}
                />
              </fieldset>

              <button
                type="submit"
                className="button primary btn btn-primary  my-4 p bold Paybtn "
              >
                <i class="fa fa-lock"></i> Pay Securely
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default CardDetails;
