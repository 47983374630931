import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import './ProductsCards.css'

import ad2 from '../../assets/body/mid2.PNG'
import ad1 from '../../assets/body/mid5.PNG'

const ProductsCards = () => {
  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1
  };


  return (
  <div className="beforeFooter">
    <hr></hr>
      <div><p className="containHeading">GREAT PRODUCTS</p></div>
      <hr></hr>
  <div className="contain">
    
<div className="Main">
  

      <Slider {...settings}>



        <div className="productImg">
          <div class="container">
            <div class="row">
              <div class="col-md-7">
                <div class="wsk-cp-product">
                  <div class="wsk-cp-img">
                    <img src="https://3.bp.blogspot.com/-eDeTttUjHxI/WVSvmI-552I/AAAAAAAAAKw/0T3LN6jABKMyEkTRUUQMFxpe6PLvtcMMwCPcBGAYYCw/s1600/001-culture-clash-matthew-gianoulis.jpg" alt="Product" class="img-responsive" />
                  </div>
                  <div class="wsk-cp-text">
                    <div class="category">
                      <span>Ethnic</span>
                    </div>
                    <div class="title-product">
                      <h3>Product1</h3>
                    </div>
                    <div class="description-prod">
                      <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good work now check this link</p>
                    </div>
                    <div class="card-footer">
                    <div class="wcf-left"> <h1 class="price">₹ 2799<small>.00</small></h1></div>
                    <div class="wcf-right"><a href="#" class="buy-btn"><ShoppingBasketIcon /></a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="productImg">
          <div class="container">
            <div class="row">
            <div class="col-md-7">
                  <div class="wsk-cp-product">
                    <div class="wsk-cp-img"><img src="https://1.bp.blogspot.com/-b-2SgNUrFHg/WVSvmewWqgI/AAAAAAAAAK0/1K4YCcbYjhokHwV_IgiVJN9mEnQoWunIwCPcBGAYYCw/s1600/fashion-portrait-nicole-6347.jpg" alt="Product" class="img-responsive" /></div>
                    <div class="wsk-cp-text">
                      <div class="category">
                        <span>Introvert</span>
                      </div>
                      <div class="title-product">
                        <h3>Product2</h3>
                      </div>
                      <div class="description-prod">
                        <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good work now check this link</p>
                      </div>
                      <div class="card-footer">
                        <div class="wcf-left"> <h1 class="price">₹ 2099<small>.00</small></h1></div>
                        <div class="wcf-right"><a href="#" class="buy-btn"><ShoppingBasketIcon /></a></div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

        
        <div className="productImg">
          <div class="container">
            <div class="row">
            <div class="col-md-7">
                  <div class="wsk-cp-product">
                    <div class="wsk-cp-img"><img src="https://1.bp.blogspot.com/-XL_Ba-178Fo/WVSvm5AbApI/AAAAAAAAAK4/X5109HTqUiAhPjbmz4NFVHcpL7ZWe6T3ACPcBGAYYCw/s1600/wow-29.jpg" alt="Product" class="img-responsive" /></div>
                    <div class="wsk-cp-text">
                      <div class="category">
                        <span>Beauty</span>
                      </div>
                      <div class="title-product">
                        <h3>Product3</h3>
                      </div>
                      <div class="description-prod">
                        <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good work now check this link</p>
                      </div>
                      <div class="card-footer">
                      <div class="wcf-left"> <h1 class="price">₹ 4299<small>.00</small></h1></div>
                      <div class="wcf-right"><a href="#" class="buy-btn"><ShoppingBasketIcon /></a></div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <div className="productImg">
          <div class="container">
            <div class="row">
            <div class="col-md-7">
                  <div class="wsk-cp-product">
                    <div class="wsk-cp-img"><img src="https://3.bp.blogspot.com/-iUes8qr4uC8/WVSvl6Wf_fI/AAAAAAAAAKs/JiTUUVvdbqEC_QGGaQhuVJiezIN3LIqEgCPcBGAYYCw/s1600/IMG_5367-bp.jpg" alt="Product" class="img-responsive" /></div>
                    <div class="wsk-cp-text">
                      <div class="category">
                        <span>Drama</span>
                      </div>
                      <div class="title-product">
                        <h3>Product4</h3>
                      </div>
                      <div class="description-prod">
                        <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good work now check this link</p>
                      </div>
                      <div class="card-footer">
                      <div class="wcf-left"> <h1 class="price">₹ 2999<small>.00</small></h1></div>
                      <div class="wcf-right"><a href="#" class="buy-btn"><ShoppingBasketIcon /></a></div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

    
      </Slider>
      
      </div>
      <div className="AD">
        <img  className="admg"  src={ad2} alt="ad"/>
        <img  className="admg"  src={ad1} alt="ad"/>

      </div>

      </div>
      </div>

  )
}


export default ProductsCards;