import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";

import "./SubCategories.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import home from "../../src/assets/home.PNG";
import img1 from "../../assets/products/1.JPG";
import img2 from "../../assets/products/2.JPG";
import img3 from "../../assets/products/3.JPG";
import img4 from "../../assets/products/4.JPG";
import img5 from "../../assets/products/5.JPG";

export const SubCategories = () => {
  const { id } = useParams();

  console.log("id is", id);

  var settings = {
    dots: true,
    infinite: true,
    speed: 1,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  const [loading, setLoading] = useState(false);
  const [Category, setCategory] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      await axios({
        method: "get",
        url: "http://54.167.190.182:8097/retail/categories/fetchAllCategories",
        headers: {},
      })
        .then(function (response) {
          console.log(
            "Got response",
            response.data.result.categoryList[0].category_name
          );
          setCategory(response.data.result.categoryList);
        })
        .catch(function (response) {
          console.log(response);
        });
      setLoading(false);
    };

    fetchPosts();
  }, []);

  console.log("cat Name", Category[0]);

  return (
    <div>
      <div class="SubCatcontainer">
        <div class="row md-8">
          <div class="col-md-8">
            <div class="card mb-1">
              <div class="card-header">Categories</div>
              {Category.map((value, i) => {
                // console.log("From return state", value);

                if (value.length < 0) {
                  return (
                    <div>
                      Loading
                      {/* <img src={home} alt="h" /> */}
                    </div>
                  );
                } else if (value.category_parent_id === 0) {
                  return (
                    <div key={i}>
                      <div class="list-group list-group-flush">
                        <a
                          href={`/subCategories/${value.category_id}`}
                          class="list-group-item list-group-item-action"
                        >
                          {value.category_name}
                        </a>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>

        <div class="col-md-9 subCon">
          <hr></hr>
          <div>
            <h2 className="SubCatHeading">Sub Cat NAME</h2>
          </div>
          <hr></hr>
          <div class="card" style={{ backgroundColor: "azure" }}>
            <div class="card-header">
              <div class="row">
                <div class="col-md-12">
                  <h2 className="subCatHeading">{Category.category_name}</h2>
                  <div class="card add-pad">
                    <div className="subf">
                      {/* <Slider {...settings} style={{ Width: "80%" }}> */}
                      {Category.map((value, i) => {
                        // console.log("From return state", value);

                        if (value.length < 0) {
                          return (
                            <div>
                              Loading
                              {/* <img src={home} alt="h" /> */}
                            </div>
                          );
                        } else if (value.category_parent_id == id) {
                          return (
                            <div key={i}>
                              {/* <div className=""> */}
                              {/* <p>Item1</p> */}
                              <a href={`/ProductList/${id}`}>
                                <div>
                                  <img
                                    className="productImg"
                                    src={img2}
                                    alt="pic2"
                                    width="130px"
                                  />
                                  <p>{value.category_description}</p>
                                </div>
                              </a>
                              <div></div>
                            </div>
                            // </div>
                          );
                        }
                      })}
                      {/* </Slider> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" style={{ backgroundColor: "bisque" }}>
              <div class="card-header">
                <div class="row">
                  <div class="col-md-12">
                    <h2 className="subCatHeading">Fruits Section </h2>
                    <div class="card add-pad">
                      <Slider {...settings} style={{ Width: "80%" }}>
                        <div className="">
                          {/* <p>Item1</p> */}
                          <img
                            className="productImg"
                            src={img2}
                            alt="pic2"
                            width="130px"
                          />
                        </div>
                        <div>
                          <img
                            className="productImg"
                            src={img3}
                            alt="pic2"
                            width="130px"
                          />
                        </div>

                        <div>
                          <img
                            className="productImg"
                            src={img4}
                            alt="pic2"
                            width="130px"
                          />
                        </div>
                        <div>
                          <img
                            className="productImg"
                            src={img5}
                            alt="pic2"
                            width="130px"
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" style={{ backgroundImage: { img1 } }}>
              <div class="card-header">
                <div class="row">
                  <div class="col-md-12">
                    <h2 className="subCatHeading">Vegetables</h2>
                    <div class="card add-pad">
                      <Slider {...settings} style={{ Width: "80%" }}>
                        <div className="">
                          {/* <p>Item1</p> */}
                          <img
                            className="productImg"
                            src={img2}
                            alt="pic2"
                            width="130px"
                          />
                        </div>
                        <div>
                          <img
                            className="productImg"
                            src={img3}
                            alt="pic2"
                            width="130px"
                          />
                        </div>

                        <div>
                          <img
                            className="productImg"
                            src={img4}
                            alt="pic2"
                            width="130px"
                          />
                        </div>
                        <div>
                          <img
                            className="productImg"
                            src={img5}
                            alt="pic2"
                            width="130px"
                          />
                        </div>

                        <div>
                          <img
                            className="productImg"
                            src={img3}
                            alt="pic2"
                            width="130px"
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
