import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import _debounce from "lodash.debounce";
//import './Slider.css'
import "./Sliders.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../assets/slider/Q1.jpg";
import img2 from "../assets/slider/P1.jpg";
import img3 from "../assets/slider/R1.jpg";
import ad from "../assets/MainPoster/p1.jpg";
import MainPoster1 from "../assets/MainPoster/p2.jpg";
import MainPoster2 from "../assets/MainPoster/p4.jpg";

import MainPoster3 from "../assets/MainPoster/api_mg.png";

import ad_1 from "../assets/MainPoster/p1-1.jpg";
import ad_2 from "../assets/MainPoster/p1-2.jpg";
import ad_3 from "../assets/MainPoster/p1-3.jpg";
import MainPosters from "./MainPosters";

const Sliders = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerWidth);
  const [slidesToShow, setslidesToShow] = useState(4);

  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 2000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };

  const handleWidth = () => {
    const PosterWidth = window.innerWidth;
    setWidth(PosterWidth);
    const PosterHeight = window.innerHeight;
    setHeight(PosterHeight);

    if (window.innerWidth < 850 && window.innerWidth > 501) {
      setslidesToShow(2);
    } else {
      setslidesToShow(4);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWidth, { passive: true });

    if (window.innerWidth < 850 && window.innerWidth > 501) {
      setslidesToShow(2);
    }else{
      setslidesToShow(4)
    }
  }, []);

  return (
    <div
      className="bodyPoster"
      style={{
        width: `${width / 1}px`,
        height: `${window.innerHeight / 1.35}px`,
      }}
    >
      <div className="topPoster">
        {/* <div class="slider_mod "> */}
        <div
          className="MainPosterAlign"
          // style={{
          //   width: `${window.width / 1.02}px`,
          //   height: `${window.innerHeight / 3.5}px`,
          // }}
        >
          <div
            className="MainPoster2"
            style={{
              backgroundImage: `url(${MainPoster1})`,
              backgroundPosition: "left center",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              // height: "100%",
              // width: "100%",
            }}
            usemap="#image-map"
          >
            <a href="/subCategories/13" className="invisibleLink"></a>

            <div className="invisibleHomeDelivery">
              <div className="invisiblePickup">
                <a href="/subCategories/13" className="invisiblePickuplink" alt="PickUp"></a>
              </div>

              <div className="delink">
                <a href="/subCategories/13" className="delinkclick" ></a>
              </div>
            </div>

    

            <div className="api_imgss">
              <Slider {...settings} style={{ Width: "10%" }}>
                <div className="">
                  {/* <p>Item1</p> */}
                  <img src={MainPoster3} alt="pic2" />
                  <div className="DetailsTitle">
                    <p>Link I</p>
                  </div>
                </div>
                <div>
                  <img src={MainPoster3} alt="pic2" />
                  <div className="DetailsTitle">
                    <p> Link II</p>
                  </div>
                </div>
                {/* <div>
      <img className="productImag"  src={img3} alt="pic2" />
      </div> */}
                <div>
                  <img src={MainPoster3} alt="pic2" />
                  <div className="DetailsTitle">
                    <p>Link III</p>
                  </div>
                </div>
                <div>
                  <img src={MainPoster3} alt="pic2" />
                  <div className="DetailsTitle">
                    <p>Link IV</p>
                  </div>
                </div>
                <div>
                  <img src={MainPoster3} alt="pic2" />
                  <div className="DetailsTitle">
                    <p>Link V</p>
                  </div>
                </div>
                <div>
                  <img src={MainPoster3} alt="pic2" />
                  <div className="DetailsTitle">
                    <p>Link VI</p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="CarouselPoster">
          <Carousel axis={'vertical'} fade={true} pause="hover" className="new2">
            <Carousel.Item interval={4000}>
              <img className="slideimgs" src={img3} alt="Third slide" />
              <Carousel.Caption>
                <h3></h3>
                <p></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={6000}>
              <img className=" slideimgs" src={img1} alt="Third slide" />
              <Carousel.Caption>
                <h3></h3>
                <p></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={6000}>
              <img className="slideimgs" src={img2} alt="Third slide" />
              <Carousel.Caption>
                <h3></h3>
                <p></p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* <div className=""> */}

        {/* <div className=" ads_link">
          <img src={ad} usemap="#image-map" className="nextImg" />
          <map name="image-map">
            <area
              target="_self"
              alt="Check Doctor NearBy"
              title="Check Doctor NearBy"
              href="#"
              coords="8,220,202,214,199,304,7,310"
              shape="poly"
            />
            <area
              target="_self"
              alt="Search Medicine"
              title="Search Medicine"
              href="#"
              coords="232,212,474,220,475,308,235,309"
              shape="poly"
            />
            <area
              target="_self"
              alt="Register As Doctor"
              title="Register As Doctor"
              href="#"
              coords="726,304,487,307,491,204,705,0,713,18"
              shape="poly"
            />
          </map>
        </div> */}

        {/* </div> */}
      </div>

      <MainPosters slidesToShow={slidesToShow} />
    </div>
  );
};

export default Sliders;
