import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import "./style/SingleProduct.css";

import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";

import money_refund from "../../assets/ProductDesc/re/money_refund.png";
import package_refund from "../../assets/ProductDesc/re/package_refund.png";
import delivery from "../../assets/ProductDesc/re/delivery.png";
import loadingImg from '../../assets/loading.gif'

import { useParams } from "react-router";

import BrowsingHistory from "./BrowsingHistory";
import ViewReview from "./ViewReview";
import DescTable from "./DescTable";
import RelatedProducts from "./RelatedProducts";
import ProductMainImg from "./ProductMainImg";
import PinCode from "./PinCode";
import Offers from "./Offers";
import StarRating from "../ProductList/StarRating";

import axios from "axios";

class SingleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productData: [],
      id: this.props.match.params.id,
      quantity_value: 1,
      star: 2,
      loading: false,
    };
  }

  async componentDidMount() {
    var id = this.state.id;
    console.log("Single Id", id);

    this.setState({ loading: true });

    await axios
      .get(`http://3.90.53.217:8090/demo/product/${id}`)
      .then((res) => {
        console.log(res.data.result);
        this.setState({
          productData: [...this.state.productData, res.data.result.product],
        });
        this.setState({ loading: false });
      });



  }

  textChange = (e) => {
    console.log("tetchng", e.target.value);
    if (e.target.value !== "") {
      this.setState({ quantity_value: e.target.value });
      console.log(this.state.quantity_value);
    }
  };

  onSubmit = (e) => {
    if (this.state.quantity_value > 0) {
      e.preventDefault();
   
      console.log("ADDED", this.state.quantity_value);
      return <Redirect to="http://localhost:3000/cart" />;
      //  return <Link to="/cart" />
    } else {
      e.preventDefault();
      alert("Quantity Cannot be less than 0!!");
    }
  };

  render() {
    console.log(this.state.PinCodeMatch);
  
    // console.log(this.state.productData)

    if(this.state.loading){
      return <h3>Loading...<img src={loadingImg} alt="" / ></h3>;
    }

    return (
      <main class="ProductDesc">
        {this.state.productData.map((value, i) => {
          return (
            <div class="containerProduct dark-grey-text mt-5" key={i}>
              {/* <!--Grid row--> */}
              <div class="row wow fadeIn">
                {/* <!--Grid column--> */}
                <div class="col-md-6 mb-1 product-img">
                  <div className="ratingStar">
                    <div>
                      <a href="#">
                        <span class=""> Brand: Oppo </span>
                      </a>
                    </div>
                    <div className="stars">
                      {/* <img src={star_yellow} alt="sy" width="15px" height="15px" /> */}
                      <StarRating star={this.state.star} />
                      <span class="a-icon-alt">
                        {this.state.star} out of 5 stars
                      </span>
                    </div>
                  </div>

                  <p className=" Ptitle">{value.name}</p>

                  <hr></hr>

                  <ProductMainImg />

                  {/* <VideoView />    */}
                  {/* <img src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Products/14.jpg" class="img-fluid" alt="" /> */}
                </div>
                {/* <!--Grid column--> */}

                {/* <!--Grid column--> */}
                <div className="col-md-5 mb-4">
                  {/* <!--Content--> */}
                  <div className="p-2 ProductComp">
                    <div className="Price">
                      <p className="Pricing">
                        <p
                          style={{
                            color: "green",
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          In Stock
                        </p>
                        <del className="CutoffAmount "> M.R.P : ₹ 12990</del>
                        <span>Price :</span>{" "}
                        <span className="RealAmount "> ₹ 9,990</span>
                        <span className="SavingAmount ">
                          Saving : ₹ 2000(20%)
                        </span>
                      </p>

                      <div className="Vendor">
                        <a href="#">
                          <span style={{ color: "black" }}>
                            {" "}
                            Vendor : Oppo Inc.
                          </span>
                        </a>

                        <span>
                          <img
                            src={money_refund}
                            alt="money_refund"
                            height="40px"
                          />
                          Money Back Garentee
                        </span>

                        <span>
                          <img
                            src={package_refund}
                            alt="money_refund"
                            height="40px"
                          />
                          7 Days Return
                        </span>

                        <span>
                          <img
                            src={delivery}
                            alt="money_refund"
                            height="40px"
                          />
                          Delivered Within 7 days
                        </span>
                      </div>
                    </div>

                    {/* <p class="lead col-md-8">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et dolor suscipit libero eos atque quia ipsa
                                sint voluptatibus!
                    Beatae sit assumenda asperiores iure at maxime atque repellendus maiores quia sapiente.</p> */}

                    <div className="Quantity">
                      <div>
                        Quantity:<br></br>{" "}
                      </div>

                      <form
                        onSubmit={this.onSubmit}
                        className="AddToCart justify-content-left"
                      >
                        <input
                          type="number"
                          value={this.state.quantity_value}
                          style={{ width: "100px" }}
                          onChange={this.textChange}
                          min="1"
                          max="10"
                          placeholder="Qantity"
                        />
                        <button
                          class="btn btn-primary  my-4 p bold  "
                          type="submit"
                        >
                          <h3>
                            Add to cart <AddShoppingCartIcon fontSize="large" />
                          </h3>
                        </button>
                      </form>
                    </div>
                    <br></br>

                    <PinCode />
                  </div>
                  <div className="Key_specs">
                    <p className="HeadDesc font-weight-bold ">
                      Key Sepecifications:
                    </p>

                    <div aria-expanded="true" className="KeySpec">
                      <table
                        id="productDetails_techSpec_section_1"
                        class="a-keyvalue prodTable"
                        role="presentation"
                      >
                        <tbody>
                          <tr>
                            <th class="a-color-secondary a-size-base prodDetSection">
                              OS
                            </th>

                            <td class="a-size-base prodDetValue">Android</td>
                          </tr>

                          <tr>
                            <th class="a-color-secondary a-size-base prodDetSection">
                              RAM
                            </th>

                            <td class="a-size-base prodDetValue">8 GB</td>
                          </tr>

                          <tr>
                            <th class="a-color-secondary a-size-base prodDetSection">
                              Product Dimensions
                            </th>

                            <td class="a-size-base prodDetValue">
                              16.4 x 0.8 x 7.6 cm; 180 Grams
                            </td>
                          </tr>

                          <tr>
                            <th class="a-color-secondary a-size-base prodDetSection">
                              Batteries
                            </th>

                            <td class="a-size-base prodDetValue">
                              1 Lithium Polymer batteries required. (included)
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <a href="#DescTable">See More Details</a>
                    </div>
                  </div>
                  <Offers />
                </div>
              </div>
              {/* <!--Grid row--> */}

              <hr />

              {/* <!--Grid row--> */}
              <div class="row  d-flex justify-content-center wow fadeIn">
                {/* <!--Grid column--> */}
                <div class="col-md-6 text-center">
                  <h4 class="my-4 head h4">Additional information</h4>

                  <p class="lead">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Natus suscipit modi sapiente illo soluta odit voluptates,
                    quibusdam officia. Neque quibusdam quas a quis porro?
                    Molestias illo neque eum in laborum.
                  </p>
                </div>
                {/* <!--Grid column--> */}

                <div className="DescTable" id="DescTable">
                  <DescTable />
                </div>
              </div>
              {/* <!--Grid row--> */}

              {/* <!--Grid row--> */}
              <div class="row wow fadeIn">
                {/* <!--Grid column--> */}
                <div class="col-lg-4 col-md-12 mb-4">
                  <img
                    src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Products/11.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                {/* <!--Grid column--> */}

                {/* <!--Grid column--> */}
                <div class="col-lg-4 col-md-6 mb-4">
                  <img
                    src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Products/12.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                {/* <!--Grid column--> */}

                {/* <!--Grid column--> */}
                <div class="col-lg-4 col-md-6 mb-4">
                  <img
                    src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Products/13.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <br></br>
                <div class="col-md-6 text-center">
                  <h4 class="my-4 head h4"> More information</h4>

                  <p class="lead">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Natus suscipit modi sapiente illo soluta odit voluptates,
                    quibusdam officia. Neque quibusdam quas a quis porro?
                    Molestias illo neque eum in laborum.
                  </p>
                </div>
                {/* <!--Grid column--> */}
              </div>
              {/* <!--Grid row--> */}
            </div>
          );
        })}

        <BrowsingHistory />

        <hr></hr>

        <ViewReview />

        <RelatedProducts />
      </main>
    );
  }
}

export default SingleProduct;
