import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import "./CartPage.css";
import RelatedProducts from "../SingleProduct/RelatedProducts";
import ProductsDeal from "../../modules/BodyModules/ProductsDeal";
import HistoryProducts from "../../modules/SlidingProducts/HistoryProducts";

export const CartPage = () => {
  const [Quantity, setQuantity] = useState(1);

  const [loading, setLoading] = useState(false);
  const [Cart, setCart] = useState([]);

  const SubtractQty = () => {
    if (Quantity > 1) {
      console.log("Subtracted");
      setQuantity(Quantity - 1);
    }
  };
  const AddQty = () => {
    console.log("Added");
    setQuantity(Quantity + 1);
  };

  const { id } = useParams();

  console.log("id is", id);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      await axios({
        method: "get",
        url: `http://54.167.190.182:8097/retail//ViewCart/${id}`,
        headers: {},
      })
        .then(function (response) {
          console.log("Cart response", response);
          // setCategory(response.data.result.categoryList);
        })
        .catch(function (response) {
          console.log(response);
        });
      setLoading(false);
    };

    fetchPosts();
  }, []);

  return (
    <div className="CartMainDiv">
      <hr></hr>
      <div>
        <h2 className="CartHeading">Complete Your Order</h2>
      </div>
      <hr></hr>

      <div className="cartContainer">
        <div class="totalCheckout">
          <div className="Checkouttables">
            <table class=" table-hover">
              <tbody className="tb">
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td className="Subtotal">
                    <h5>Subtotal:</h5>
                  </td>
                  <td class="text-right Subtotal">
                    <h5>
                      <strong> ₹ 29991</strong>
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td className="Subtotal">
                    <h5>Shipping Charges:</h5>
                  </td>
                  <td class="text-right Subtotal">
                    <h5>
                      <strong> ₹ 200</strong>
                    </h5>
                  </td>
                </tr>
                <tr className="SavingTotal">
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td>
                    <h5 className="SavingTotal">Total Savings: </h5>
                  </td>
                  <td class="text-right">
                    <h5>
                      <strong className="SavingTotal"> ₹ 2900</strong>
                    </h5>
                  </td>
                </tr>{" "}
                <hr></hr>
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>

                  <td className="Totaling">
                    <h3>Total</h3>
                  </td>
                  <td class="text-right Totaling ">
                    <h3>
                      <strong> ₹ 26191</strong>
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td></td>
                  <td>
                    <a href="/checkout">
                      <button type="button" class="btn btn-success checkoutBtn">
                        Checkout <span class="fa fa-play"></span>
                      </button>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td></td>
                  <td>
                    <a href="/"><button type="button" class="btn btn-default">
                      <span class="fa fa-shopping-cart"></span> Continue
                      Shopping
                    </button>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* //Cart */}

        <div class="col-sm-12 col-md-9 px-md- col-md-offset-1 mt- mt-md-">
          <h2
            className="HeaderCartMenu"
            style={{ fontWeight: "bold", padding: "5px 0px" }}
          >
            <span>CART</span>
          </h2>
          <table class="tables table-hover">
            <thead className="Totaling">
              <tr className="HeadingCart">
                <th>Product Description</th>

                <th class="text-center QtyCart">QTY</th>
                <th class="text-center OfferMRP"> M.R.P</th>
                <th class="text-center OfferDiscount">Discount</th>
                <th class="text-center TotalCart">Total</th>

                <th> </th>
              </tr>
            </thead>
            <tbody className="thb">
              <tr>
                <td class="col-sm-1 col-md-1">
                  <div class="media ">
                    <a class="thumbnail pull-left" href="#">
                      <img
                        class="media-object"
                        src="http://icons.iconarchive.com/icons/custom-icon-design/flatastic-2/72/product-icon.png"
                        style={{ width: 72, height: 72 }}
                        alt="Google"
                      />
                    </a>
                    <div class="media-body">
                      <h4 class="media-heading">
                        <a href="#">Package Name</a>
                      </h4>
                      <h5 class="media-heading">
                        {" "}
                        Sold by <a href="#">Brand name</a>
                      </h5>
                      
                      <span class="text-warning">
                      <span>Status: </span><strong>In Stock</strong>
                      </span>
                    </div>
                  </div>
                </td>
                <td class="col-sm-1 col-md-1 tds" style={{ textAlign: "center" }}>
                <span className="RespTEXT">Quantity:</span>
                <div className="qtybtn">
                  <button onClick={SubtractQty} style={{ border: "white" }}>
                    <RemoveIcon style={{ color: "red" }} />
                  </button>

                  <span className="qtyValue"> {Quantity}</span>

                  <button onClick={AddQty} style={{ border: "white" }}>
                    <AddIcon style={{ color: "green" }} />
                  </button>
                  </div>
                </td>
              

                <td class="text-center OfferMRP">
                  <strong> ₹ 12900</strong>
                </td>
                <td className="text-center">
                  <strong class="label label-danger OfferDiscount "> - ₹ 3000</strong>
                </td>
                <td class="col-sm-1 col-md-1 tds">
                <span className="RespTEXT">Price: </span>
                  <strong> ₹ {Quantity * 9900}</strong>
                </td>

                <td class="col-sm-1 col-md-1 ">
                  <button type="button" class="btn btn-danger">
                    <span class="fa fa-remove"></span> Remove
                  </button>
                </td>
              </tr>
<hr></hr>
<tr>
                <td class="col-sm-1 col-md-1">
                  <div class="media ">
                    <a class="thumbnail pull-left" href="#">
                      <img
                        class="media-object"
                        src="http://icons.iconarchive.com/icons/custom-icon-design/flatastic-2/72/product-icon.png"
                        style={{ width: 72, height: 72 }}
                        alt="Google"
                      />
                    </a>
                    <div class="media-body">
                      <h4 class="media-heading">
                        <a href="#">Package Name</a>
                      </h4>
                      <h5 class="media-heading">
                        {" "}
                        Sold by <a href="#">Brand name</a>
                      </h5>
                      
                      <span class="text-warning">
                      <span>Status: </span><strong>In Stock</strong>
                      </span>
                    </div>
                  </div>
                </td>
                <td class="col-sm-1 col-md-1 tds" style={{ textAlign: "center" }}>
                <span className="RespTEXT">Quantity:</span>
                <div className="qtybtn">
                  <button onClick={SubtractQty} style={{ border: "white" }}>
                    <RemoveIcon style={{ color: "red" }} />
                  </button>

                  <span className="qtyValue"> {Quantity}</span>

                  <button onClick={AddQty} style={{ border: "white" }}>
                    <AddIcon style={{ color: "green" }} />
                  </button>
                  </div>
                </td>
              

                <td class="text-center OfferMRP">
                  <strong> ₹ 12900</strong>
                </td>
                <td className="text-center">
                  <strong class="label label-danger OfferDiscount "> - ₹ 3000</strong>
                </td>
                <td class="col-sm-1 col-md-1 tds">
                <span className="RespTEXT">Price: </span>
                  <strong> ₹ {Quantity * 9900}</strong>
                </td>

                <td class="col-sm-1 col-md-1 ">
                  <button type="button" class="btn btn-danger">
                    <span class="fa fa-remove"></span> Remove
                  </button>
                </td>
              </tr>

              {/* <hr></hr> */}
              <br></br>
            </tbody>
          </table>


      <div className="ResponsiveCartTable">



      </div>


          <div style={{ zoom: "0.6" }}>
            <RelatedProducts />
            <HistoryProducts />


          </div>
        </div>
      </div>
    </div>
  );
};
