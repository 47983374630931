import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./ProductsDeal.css";

import img1 from "../../assets/products/1.JPG";
import img2 from "../../assets/products/2.JPG";
import img3 from "../../assets/products/3.JPG";
import img4 from "../../assets/products/4.JPG";
import img5 from "../../assets/products/5.JPG";
import img6 from "../../assets/products/6.JPG";
import { Rating } from "@material-ui/lab";
import StarRating from "../../Pages/ProductList/StarRating";

export default function ProductsDeal() {
  const [slidesToShow, setslidesToShow] = useState(Number);

  const handleWidth = () => {
    // const PosterWidth = window.innerWidth;
    // setWidth(PosterWidth);
    // const PosterHeight = window.innerHeight;
    // setHeight(PosterHeight);

    if (window.innerWidth < 501) {
      setslidesToShow(2);
    } else if (window.innerWidth > 501 && window.innerWidth < 801) {
      setslidesToShow(3);
    } else {
      setslidesToShow(4);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWidth, { passive: true });

    if (window.innerWidth < 501) {
      setslidesToShow(2);
    } else {
      setslidesToShow(4);
    }
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };

  return (
    <div className="mySlider">
      <p className="HeaderDeal"> Todays' Deals</p>
      <Slider {...settings} style={{ height: "80%" }}>
        <div className="n">
          {/* <p>Item1</p> */}
          <img className="productImag" src={img1} alt="pic2" />
          <div className="DetailsTitle">
            <p>
              Omron MC 720 Non Contact Digital Infrared 
            </p>
            <p>
              <StarRating star={3} />
            </p>
            <p className="price">₹ 1290.00</p>
          </div>
        </div>
        <div className="n">
          <img className="productImag" src={img2} alt="pic2" />
          <div className="DetailsTitle">
            <p>Redragon Karura K502 Wired USB Gaming Keyboard</p>
            <p>
              <StarRating star={1} />
            </p>
            <p className="price">₹ 1290.00</p>
          </div>
        </div>
        <div className="n">
          <img className="productImag" src={img4} alt="pic2" />
          <div className="DetailsTitle">
            <p>
              Rich Dad Poor Dad: What 
            </p>
            <p>
              Rating
              {/* <StarRating /> */}
            </p>
            <p className="price">₹ 1290.00</p>
          </div>
        </div>
        <div className="n">
          <img className="productImag" src={img5} alt="pic2" />
          <div className="DetailsTitle">
            <p>
              Rich Dad Poor Dad: What 
            </p>
            <p>
              Rating
              {/* <StarRating /> */}
            </p>
            <p className="price">₹ 1290.00</p>
          </div>
        </div>
        <div className="n">
          <img className="productImag" src={img3} alt="pic2" />
          <div className="DetailsTitle">
            <p>
              Rich Dad Poor Dad: What the 
            </p>
            <p>
              Rating
              {/* <StarRating /> */}
            </p>
            <p className="price">₹ 1290.00</p>
          </div>
        </div>
        <div className="n">
          <img className="productImag" src={img6} alt="pic2" />
          <div className="DetailsTitle">
            <p>
              Rich Dad Poor Dad: What the Rich 
            </p>
            <p>
              Rating
              {/* <StarRating /> */}
            </p>
            <p className="price">₹ 1290.00</p>
          </div>
        </div>
      </Slider>
    </div>
  );
}
