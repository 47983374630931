import React, { useEffect, useState } from "react";
import ReactImageZoom from "react-image-zoom";
import GallaryView from "./GallaryView";
import VideoView from "./VideoView";

import './style/ProductMainImg.css'

import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

export default function ProductMainImg() {
  // const fetchURL = "https://jsonplaceholder.typicode.com/todos/";
  // const getItems = () => fetch(fetchURL).then(res => res.json());

  const [allImages, setallImages] = useState([
    "https://images-na.ssl-images-amazon.com/images/I/71AHtKejSlL._SL1500_.jpg",
    "https://images-na.ssl-images-amazon.com/images/I/71RcXQBjXrL._SL1500_.jpg",
    "https://images-na.ssl-images-amazon.com/images/I/71Q4i6uQLkL._SL1500_.jpg",
    "https://images-na.ssl-images-amazon.com/images/I/71oisYj9pRL._SL1500_.jpg",
    "https://images-na.ssl-images-amazon.com/images/I/715R4JNLNOL._SL1500_.jpg",
  ]);

  const [allVideos, setallVideos] = useState([
    "https://www.youtube.com/embed/XXYlFuWEuKI",
  ]);

  const [selectedImg, setSelectedImg] = useState(allImages[0]);

  const addI = () => {
    const a = [];
    for (let i = 0; i < 5; i += 1) {
      a.push(`https://picsum.photos/id/${i}/1200/600`);
      // setallImages (`https://picsum.photos/id/${i}/163/100`)
    }
    setallImages(...a);
    console.log("clickde", allImages);
  };

  useEffect(() => {
    //   const a = [];
    //     for (let i = 0; i < 5; i += 1) {
    //         a.push(`https://picsum.photos/id/${i}/163/100`)
    //      }
    //      setallImages(a)
    // getItems().then(data => setallImages(data));
  });

  const props = {
    width: 400,
    height: 400,
    zoomPosition: "original",
    scale: 1.1,
    zoomWidth: 7,

    img: selectedImg,
  };

  const currentImg = (cI) => {
    console.log("clickingImg", cI);
    setSelectedImg(cI);
  };

  return (
    <div >

      
      <div  className="gallery"
        style={{
          width: "400px",
          margin: "20px",
          marginLeft: "120px",
          marginBottom: "30px",
        }}
      >
        <div className="VideoView">
        <VideoView />
        </div>
       
        <div className="ZoomArea">
        <ReactImageZoom className="imgP" {...props} />
        </div>
      
       
      </div>
      
      <p>
        <FavoriteBorderIcon fontSize="large" color="action" />
        Add to WishList
      </p>
     
    
       
        <GallaryView
          allImages={allImages}
          currentImg={currentImg}
          allVideos={allVideos}
        />
      
    </div>
  );
}
