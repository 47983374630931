import React, { useState } from "react";
// import ReactDOM from 'react-dom'
import ModalVideo from "react-modal-video";
import "./style/VideoView.css";

import "../../../node_modules/react-modal-video/scss/modal-video.scss";

const VideoView = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="03isxzvEEjw"
        onClose={() => setOpen(false)}
      />

      {/* <button className="btn-primary" onClick={() => setOpen(true)}>
        VIEW DEMO
      </button> */}
      <div class="video-wrapper" >
       <img
          className="PlayImg"
          width="100"
          src="https://img.youtube.com/vi/03isxzvEEjw/0.jpg"
          alt={"sa"}
          onClick={() => setOpen(true)}
        />
        
        <p >Video</p>
      </div>
    </React.Fragment>
  );
};

export default VideoView;
