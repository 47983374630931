import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StarRating from "../../Pages/ProductList/StarRating";
import './ProductsSlider.css'

import img1 from "../../assets/products/1.JPG";
import img2 from "../../assets/products/2.JPG";
import img3 from "../../assets/products/3.JPG";
import img4 from "../../assets/products/4.JPG";
import img5 from "../../assets/products/5.JPG";
import img6 from "../../assets/products/6.JPG";


export default function ProductsSlider() {

  const [slidesToShow, setslidesToShow] = useState(Number);


  const handleWidth = () => {
    // const PosterWidth = window.innerWidth;
    // setWidth(PosterWidth);
    // const PosterHeight = window.innerHeight;
    // setHeight(PosterHeight);

    if ( window.innerWidth < 501) {
      setslidesToShow(2);
    } else if(window.innerWidth > 501 && window.innerWidth < 801) {
      setslidesToShow(3);
    } else {
      setslidesToShow(4);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWidth, { passive: true });

    if (window.innerWidth < 601 ) {
      setslidesToShow(2);
    }else{
      setslidesToShow(4)
    }
  }, []);



  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1
  };


  return (
 
    <div className="Slider mySlider">
	 	<p className="HeaderMpro"> More Products</p>
     <div className="SliderDiv">
      <Slider {...settings}>
      <div className="n">
        {/* <p>Item1</p> */}
        <a href="#">
        <img className="productImag" src={img1} alt="pic2" />
        <div className="DetailsTitle">
              <p>
              Omron MC 720 Non Contact Digital Infrared Forehead 
              </p>
              <p>
                
                <StarRating  star={3} />
              </p>
              <p className="price">₹ 1290.00</p>
            </div>
            </a>
      </div>
      
      <div>
      <img className="productImag"  src={img2} alt="pic2" />
      <div className="DetailsTitle">
              <p>
              Redragon Karura K502 Wired USB Gaming Keyboard with
              </p>
              <p>
           
                <StarRating star={1} />
              </p>
              <p className="price">₹ 1290.00</p>
            </div>
      </div>
      {/* <div>
      <img className="productImag"  src={img3} alt="pic2" />
      </div> */}
      <div>
      <img className="productImag"  src={img4} alt="pic2" />
      <div className="DetailsTitle">
              <p>
                Rich Dad Poor Dad: What
              </p>
              <p>
                Rating
                {/* <StarRating /> */}
              </p>
              <p className="price">₹ 1290.00</p>
            </div>
      </div>
      <div>
      <img className="productImag"  src={img5} alt="pic2" />
      <div className="DetailsTitle">
              <p>
                Rich Dad Poor Dad: What the 
              </p>
              <p>
                Rating
                {/* <StarRating /> */}
              </p>
              <p className="price">₹ 1290.00</p>
            </div>
      </div>
	  <div>
      <img className="productImag"  src={img3} alt="pic2" />
      <div className="DetailsTitle">
              <p>
                Rich Dad Poor Dad: 
              </p>
              <p>
                Rating
                {/* <StarRating /> */}
              </p>
              <p className="price">₹ 1290.00</p>
            </div>
      </div>
	  <div>
      <img className="productImag"  src={img6} alt="pic2" />
      <div className="DetailsTitle">
              <p>
                Rich Dad Poor Dad: What the 
              </p>
              <p>
                Rating
                {/* <StarRating /> */}
              </p>
              <p className="price">₹ 1290.00</p>
            </div>
      </div>
    </Slider></div>
    </div>
  
  );
}