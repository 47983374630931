import React from "react";

export default function StarRating( star )  {
  
// console.log("star",star.star);

    const totalStar = [];

        for (let i = 1; i <= star.star; i++) {
            // console.log('star', this.props.star);
            totalStar.push(i);
          }

            return (
                <div >
                    { totalStar.map((l, i) => (

                
                    // <li key={i}> a </li>

                    // <p key={i}  class="text-warning d-flex align-items-center mb-2">
                        <i style={{ marginTop : "20px" }} class="fa fa-star star-color" aria-hidden="true"></i>

                    // </p>
                
                    ))}
                </div>
            )

        }
    


// export default StarRating;