import React, { useState } from "react";
import Popup from "reactjs-popup";

// import Rating from "react-rating";
import { Rating } from "@material-ui/lab";
// import 'reactjs-popup/dist/index.css';

import "./style/NewReview.css";

import EmptyStar2 from "../../assets/Rating/empty_star.png";
import FilledStar from "../../assets/Rating/filled-star.svg";

export default function NewReview() {
  const [ifSignIn, setSignIn] = useState(true);

  if (ifSignIn) {
    return (
      <div className="AddReview">
        <Popup
          trigger={<button className="title"> Give Your Review</button>}
          position="right center bold"
        >
          <div className="popReview">
            <h2 id="fh2">WE APPRECIATE YOUR REVIEW!</h2>
            <h6 id="fh6">
              Your review will help us to improve our web hosting quality
              products, and customer services.
            </h6>

            <form id="feedback" action="">
              <div class="pinfo">Your personal info</div>

              <div class="form-group">
                <div class="col-md-8 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="fa fa-user"></i>
                    </span>
                    <input
                      name="name"
                      placeholder="John Doe"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-md-8 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="fa fa-envelope"></i>
                    </span>
                    <input
                      name="email"
                      type="email"
                      class="form-control"
                      placeholder="john.doe@yahoo.com"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-md-8 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="fa fa-globe"></i>
                    </span>
                    <input
                      name="URL"
                      placeholder="https://google.com"
                      class="form-control"
                      type="url"
                    />
                  </div>
                </div>
              </div>

              <div class="pinfo">Rate our overall services.</div>

              <div class="form-group">
                <div class="col-md-8 inputGroupContainer">
                <Rating />
                  {/* <div class="input-group">
  <span class="input-group-addon"><i class="fa fa-heart"></i></span>
   <select class="form-control" id="rate">
      <option value="1star">1</option>
      <option value="2stars">2</option>
      <option value="3stars">3</option>
      <option value="4stars">4</option>
      <option value="5stars">5</option>
    </select>
    </div> */}
                </div>
              </div>

              <div class="pinfo">Write your feedback.</div>

              <div class="form-group">
                <div class="col-md-12 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="fa fa-pencil"></i>
                    </span>
                    <textarea
                      class="form-control"
                      id="review"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </Popup>
      </div>
    );
  } else {
    return <div></div>;
  }
}
