import React from "react";
import './OrderHistory.css'

export const OrderHistory = () => {
  return (
    <div className="OrderHistory">
       <hr></hr>
        <h2 class="HeaderOrder">Your Orders</h2>
        <hr></hr>
      <div class="Ordercontainer mt-3 mt-md-5">
       
        {/* <h5 class="text-charcoal hidden-md-up">Your Orders</h5> */}
        <div class="row">
          <div class="col-12">
            <div class="list-group mb-5">
              <div
                class="list-group-item p-3 bg-snow"
                style={{position: "relative"}}
              >
                <div class="row w-100 no-gutters">
                  <div class="col-6 col-md">
                    <h6 class="text-charcoal mb-0 w-100">Order Number</h6>
                    <a href="" class="text-pebble mb-0 w-100 mb-2 mb-md-0">
                      #A915AFLJUSFHG32
                    </a>
                  </div>
                  <div class="col-6 col-md">
                    <h6 class="text-charcoal mb-0 w-100">Date</h6>
                    <p class="text-pebble mb-0 w-100 mb-2 mb-md-0">
                      May 1, 2021
                    </p>
                  </div>
                  <div class="col-6 col-md">
                    <h6 class="text-charcoal mb-0 w-100">Total</h6>
                    <p class="text-pebble mb-0 w-100 mb-2 mb-md-0">₹  2019.54</p>
                  </div>
                  <div class="col-6 col-md">
                    <h6 class="text-charcoal mb-0 w-100">Shipped To</h6>
                    <p class="text-pebble mb-0 w-100 mb-2 mb-md-0">
                      Home Address
                    </p>
                  </div>
                  <div class="col-12 col-md-3">
                    <a href="" class="btn btn-primary w-100">
                      View Order
                    </a>
                  </div>
                </div>
              </div>
              <div class="list-group-item p-3 bg-white">
                <div class="row no-gutters">
                  <div class="col-12 col-md-9 pr-0 pr-md-3">
                    <div class="alert p-2 alert-success w-100 mb-0">
                      <h6 class="text-green mb-0">
                        <b>Shipped</b>
                      </h6>
                      <p class="text-green hidden-sm-down mb-0">
                        Est. delivery between May 1 – May 5th, 2021
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <a href="" class="btn btn-secondary w-100 mb-2">
                      Track Shipment
                    </a>
                  </div>
             
                  <div class="row no-gutters mt-3">
                    <div class="col-3 col-md-1">
                      <img
                        class="img-fluid pr-3"
                        src="http://icons.iconarchive.com/icons/custom-icon-design/flatastic-2/72/product-icon.png"
                        alt=""
                      />
                    </div>
                    <div class="col-9 col-md-8 pr-0 pr-md-3">
                      <h6 class="text-charcoal mb-2 mb-md-1">
                        <a href="" class="text-charcoal">
                          1 x URGE Basics iPhone 6/iPhone 6 Plus Magnetic Wallet
                          Case
                        </a>
                      </h6>
                      <ul class="list-unstyled text-pebble mb-2 small">
                        <li class="">
                          <b>Color:</b> Red
                        </li>
                        <li class="">
                          <b>Size:</b> L
                        </li>
                      </ul>
                      <h6 class="text-charcoal text-left mb-0 mb-md-2">
                        <b>₹  1019.54</b>
                      </h6>
                    </div>
                    <div class="col-12 col-md-3 hidden-sm-down">
                      <a href="" class="btn btn-secondary w-100 mb-2">
                        Buy It Again
                      </a>
                      <a href="" class="btn btn-secondary w-100">
                        Request a Return
                      </a>
                    </div>
                  </div>
                  <div class="row no-gutters mt-3">
                    <div class="col-3 col-md-1">
                      <img
                        class="img-fluid pr-3"
                        src="http://icons.iconarchive.com/icons/custom-icon-design/flatastic-2/72/product-icon.png"
                        alt=""
                      />
                    </div>
                    <div class="col-9 col-md-8 pr-0 pr-md-3">
                      <h6 class="text-charcoal mb-2 mb-md-1">
                        <a href="" class="text-charcoal">
                          1 x URGE Basics iPhone 6/iPhone 6 Plus Magnetic Wallet
                          Case
                        </a>
                      </h6>
                      <ul class="list-unstyled text-pebble mb-2 small">
                        <li class="">
                          <b>Color:</b> Red
                        </li>
                        <li class="">
                          <b>Size:</b> L
                        </li>
                      </ul>
                      <h6 class="text-charcoal text-left mb-0 mb-md-2">
                        <b>₹ 919.54</b>
                      </h6>
                    </div>
                    <div class="col-12 col-md-3 hidden-sm-down">
                      <a href="" class="btn btn-secondary w-100 mb-2">
                        Buy It Again
                      </a>
                      <a href="" class="btn btn-secondary w-100">
                        Request a Return
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

          <div class="row">
          <div class="col-12">
            <div class="list-group mb-5">
              <div
                class="list-group-item p-3 bg-snow"
                style={{position: "relative"}}
              >
                <div class="row w-100 no-gutters">
                  <div class="col-6 col-md">
                    <h6 class="text-charcoal mb-0 w-100">Order Number</h6>
                    <a href="" class="text-pebble mb-0 w-100 mb-2 mb-md-0">
                      #A915AFLE4FO
                    </a>
                  </div>
                  <div class="col-6 col-md">
                    <h6 class="text-charcoal mb-0 w-100">Date</h6>
                    <p class="text-pebble mb-0 w-100 mb-2 mb-md-0">
                      May 5th, 2021
                    </p>
                  </div>
                  <div class="col-6 col-md">
                    <h6 class="text-charcoal mb-0 w-100">Total</h6>
                    <p class="text-pebble mb-0 w-100 mb-2 mb-md-0">₹  3060.50</p>
                  </div>
                  <div class="col-6 col-md">
                    <h6 class="text-charcoal mb-0 w-100">Shipped To</h6>
                    <p class="text-pebble mb-0 w-100 mb-2 mb-md-0">
                      Office Address
                    </p>
                  </div>
                  <div class="col-12 col-md-3">
                    <a href="" class="btn btn-primary w-100">
                      View Order
                    </a>
                  </div>
                </div>
              </div>
              <div class="list-group-item p-3 bg-white">
                <div class="row no-gutters">
                  <div class="col-12 col-md-9 pr-0 pr-md-3">
                    <div class="alert p-2 alert-success w-100 mb-0">
                      <h6 class="text-green mb-0">
                        <b>Shipped</b>
                      </h6>
                      <p class="text-green hidden-sm-down mb-0">
                        Est. delivery between May 5 – May 9th, 2021
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <a href="" class="btn btn-secondary w-100 mb-2">
                      Track Shipment
                    </a>
                  </div>
                  <div class="row no-gutters mt-3">
                    <div class="col-3 col-md-1">
                      <img
                        class="img-fluid pr-3"
                        src="http://icons.iconarchive.com/icons/custom-icon-design/flatastic-2/72/product-icon.png"
                        alt=""
                      />
                    </div>
                    <div class="col-9 col-md-8 pr-0 pr-md-3">
                      <h6 class="text-charcoal mb-2 mb-md-1">
                        <a href="" class="text-charcoal">
                          1 x URGE Basics iPhone 6/iPhone 6 Plus Magnetic Wallet
                          Case
                        </a>
                      </h6>
                      <ul class="list-unstyled text-pebble mb-2 small">
                        <li class="">
                          <b>Color:</b> Red
                        </li>
                        <li class="">
                          <b>Size:</b> L
                        </li>
                      </ul>
                      <h6 class="text-charcoal text-left mb-0 mb-md-2">
                        <b>₹ 1119.54</b>
                      </h6>
                    </div>
                    <div class="col-12 col-md-3 hidden-sm-down">
                      <a href="" class="btn btn-secondary w-100 mb-2">
                        Buy It Again
                      </a>
                      <a href="" class="btn btn-secondary w-100">
                        Request a Return
                      </a>
                    </div>
                  </div>
                  <div class="row no-gutters mt-3">
                    <div class="col-3 col-md-1">
                      <img
                        class="img-fluid pr-3"
                        src="http://icons.iconarchive.com/icons/custom-icon-design/flatastic-2/72/product-icon.png"
                        alt=""
                      />
                    </div>
                    <div class="col-9 col-md-8 pr-0 pr-md-3">
                      <h6 class="text-charcoal mb-2 mb-md-1">
                        <a href="" class="text-charcoal">
                          1 x URGE Basics iPhone 6/iPhone 6 Plus Magnetic Wallet
                          Case
                        </a>
                      </h6>
                      <ul class="list-unstyled text-pebble mb-2 small">
                        <li class="">
                          <b>Color:</b> Red
                        </li>
                        <li class="">
                          <b>Size:</b> L
                        </li>
                      </ul>
                      <h6 class="text-charcoal text-left mb-0 mb-md-2">
                        <b>₹  1019.54</b>
                      </h6>
                    </div>
                    <div class="col-12 col-md-3 hidden-sm-down">
                      <a href="" class="btn btn-secondary w-100 mb-2">
                        Buy It Again
                      </a>
                      <a href="" class="btn btn-secondary w-100">
                        Request a Return
                      </a>
                    </div>
                  </div>
                  <div class="row no-gutters mt-3">
                    <div class="col-3 col-md-1">
                      <img
                        class="img-fluid pr-3"
                        src="http://icons.iconarchive.com/icons/custom-icon-design/flatastic-2/72/product-icon.png"
                        alt=""
                      />
                    </div>
                    <div class="col-9 col-md-8 pr-0 pr-md-3">
                      <h6 class="text-charcoal mb-2 mb-md-1">
                        <a href="" class="text-charcoal">
                          1 x URGE Basics iPhone 6/iPhone 6 Plus Magnetic Wallet
                          Case
                        </a>
                      </h6>
                      <ul class="list-unstyled text-pebble mb-2 small">
                        <li class="">
                          <b>Color:</b> Red
                        </li>
                        <li class="">
                          <b>Size:</b> L
                        </li>
                      </ul>
                      <h6 class="text-charcoal text-left mb-0 mb-md-2">
                        <b>₹ 919.54</b>
                      </h6>
                    </div>
                    <div class="col-12 col-md-3 hidden-sm-down">
                      <a href="" class="btn btn-secondary w-100 mb-2">
                        Buy It Again
                      </a>
                      <a href="" class="btn btn-secondary w-100">
                        Request a Return
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

      </div>

 
    </div>
  );
};
