import React, { Component } from "react";
import "./Clothing.css";

import mid4 from "../../assets/body/mid4.PNG";

const Clothing = () => {


     document.getElementsByClassName("card")

  return (
    <div className="SectionCards">
      <hr></hr>
      <div><p className="midHeading">Collections With Best Offers</p></div>
      <hr></hr>
      {/* <div class="mid">
        <img src={mid4} alt="clothing" />
      </div> */}

      <div className="cardDiv mid">
      <div class="cardC" style={{ content: "fd" }}>
     <a href="">
        <div class="imgBx">
            <img src="https://robohash.org/40dbn3zn731nu" alt="" />
        </div>
        <div class="contextBx">
            <h3>T-shirt</h3>
            <h2 class="price">Starting @ ₹ 299<small>.00</small></h2>
            <a href="#" class="buy">Check Out</a>
        </div>
        </a>
    </div>

 



    <div class="cardC" style={{ content: "fd" }}>
        <div class="imgBx">
            <img src="https://i.postimg.cc/259swWhF/22723ede15755b068b7a355e0ad5d65e-headphone-clipart-beats-headphone-pencil-and-in-color-headphone-10.png" alt="" />
        </div>
        <div class="contextBx">
            <h3>Headphones</h3>
            <h2 class="price">Starting @ ₹ 899<small>.00</small></h2>
            <a href="#" class="buy">Check Out</a>
        </div>
    </div>  <div class="cardC" style={{ content: "fd" }}>
        <div class="imgBx">
            <img src="https://i.postimg.cc/259swWhF/22723ede15755b068b7a355e0ad5d65e-headphone-clipart-beats-headphone-pencil-and-in-color-headphone-10.png" alt="" />
        </div>
        <div class="contextBx">
            <h3>T-shirt</h3>
            <h2 class="price">Starting @ ₹ 1245<small>.00</small></h2>
            <a href="#" class="buy">Check Out</a>
        </div>
    </div>  <div class="cardC" style={{ content: "fd" }}>
        <div class="imgBx">
            <img src="https://i.postimg.cc/259swWhF/22723ede15755b068b7a355e0ad5d65e-headphone-clipart-beats-headphone-pencil-and-in-color-headphone-10.png" alt="" />
        </div>
        <div class="contextBx">
            <h3>T-shirt</h3>
            <h2 class="price">Starting @ ₹ 1245<small>.00</small></h2>
            <a href="#" class="buy">Check Out</a>
        </div>
    </div>  



    </div>

    </div>
  );
};

export default Clothing;
