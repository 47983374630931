import React, { useState, useEffect, useLayoutEffect } from "react";
import _debounce from "lodash.debounce";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./MainPoster.css";

import MainPoster1 from "../assets/MainPoster/p2.jpg";
import MainPoster2 from "../assets/MainPoster/p4.jpg";

import MainPoster3 from "../assets/MainPoster/api_mg.png";
import ad from "../assets/MainPoster/p1.jpg";

const MainPosters = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  var settingsP2 = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 2000,
    slidesToShow: props.slidesToShow,
    slidesToScroll: 1,
    rtl: true,
    useCSS: true,
  };

  useEffect(() => {
    const handleResize = _debounce(() => setWidth(window.innerWidth), 100);

    window.addEventListener("resize", handleResize);
    // console.log("widtgh", width)
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="bottomPoster">
      <div
        className="MainPosterAlign2"
        // style={{
        //   width: `${window.width / 1.02}px`,
        //   height: `${window.innerHeight / 3.5}px`,
        // }}
      >
        {/* <div
        className="MainPoster1"
        style={{
          backgroundImage: `url(${MainPoster1})`,
          backgroundPosition: "left center",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
  
        }}
      >
        <a href="/subCategories/13" className="invisibleLink">
        </a>
        <div className="api_imgss">
          <Slider {...settings} style={{ Width: "10%" }}>
            <div className="">

              <img src={MainPoster3} alt="pic2"  />
              <div className="DetailsTitle">
                <p>Link I</p>
              </div>
            </div>
            <div>
              <img src={MainPoster3} alt="pic2"  />
              <div className="DetailsTitle">
                <p> Link II</p>
              </div>
            </div>
        
            <div>
              <img src={MainPoster3} alt="pic2"  />
              <div className="DetailsTitle">
                <p>Link III</p>
              </div>
            </div>
            <div>
              <img src={MainPoster3} alt="pic2"  />
              <div className="DetailsTitle">
                <p>Link IV</p>
              </div>
            </div>
            <div>
              <img src={MainPoster3} alt="pic2"  />
              <div className="DetailsTitle">
                <p>Link V</p>
              </div>
            </div>
            <div>
              <img src={MainPoster3} alt="pic2"  />
              <div className="DetailsTitle">
                <p>Link VI</p>
              </div>
            </div>
          </Slider>
        </div>
      </div> */}
        {/* <div className="MainPoster2">
        <a href="#">
          <img src={MainPoster2} alt="p2" />
        </a>
      </div> */}

        {/* APPERAL */}
        <div
          className="MainPoster2"
          style={{
            backgroundImage: `url(${MainPoster2})`,
            backgroundPosition: "left center",
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            // height: "100%",
            // width: "100%",
          }}
        >
          <a href="/subCategories/1" className="invisibleLink">
          
          </a>
          <div className="api_imgsss">
            <Slider {...settingsP2} style={{ Width: "100%" }}>
              <div className="">
                {/* <p>Item1</p> */}
                <img src={MainPoster3} alt="pic2" />
                <div className="DetailsTitle">
                  <p>Link I</p>
                </div>
              </div>
              <div>
                <img src={MainPoster3} alt="pic2" />
                <div className="DetailsTitle">
                  <p> Link II</p>
                </div>
              </div>
              {/* <div>
      <img className="productImag"  src={img3} alt="pic2" />
      </div> */}
              <div>
                <img src={MainPoster3} alt="pic2" />
                <div className="DetailsTitle">
                  <p>Link III</p>
                </div>
              </div>
              <div>
                <img src={MainPoster3} alt="pic2" />
                <div className="DetailsTitle">
                  <p>Link IV</p>
                </div>
              </div>
              <div>
                <img src={MainPoster3} alt="pic2" height="100%" />
                <div className="DetailsTitle">
                  <p>Link V</p>
                </div>
              </div>
              <div>
                <img src={MainPoster3} alt="pic2" height="100%" />
                <div className="DetailsTitle">
                  <p>Link VI</p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className=" ads_link ">
        <img src={ad} usemap="#image-map" className="nextImg" />
        <map name="image-map">
          <area
            target="_self"
            alt="Check Doctor NearBy"
            title="Check Doctor NearBy"
            href="/Doctors"
            coords="8,220,202,214,199,304,7,310"
            shape="poly"
          />
          <area
            target="_blank"
            alt="Search Medicine"
            title="Search Medicine"
            href="?search"
            coords="225,158,234,232,473,231,462,178"
            shape="poly"
          />
          <area
            target="_self"
            alt="Register As Doctor"
            title="Register As Doctor"
            href="/usersignup"
            coords="726,304,487,307,491,204,705,0,713,18"
            shape="poly"
          />
        </map>
      </div>
    </div>
  );
};

export default MainPosters;
