import React from "react";
import { Carousel } from "react-bootstrap";
import './HeadSlider.css'


const HeaderSlider = () => {


  

  return (

        <div class="head_slided">
          <div className="SignInLink">
            <a href="/usersign" > Sign In</a>
          </div>
      <Carousel slide={true} pause="hover" className="newS">
        <Carousel.Item interval={2000}>
          ORDER ONLINE AND PICK 
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          BECOME SALES PROMOTOR
        </Carousel.Item>
        <Carousel.Item interval={2000} >
          BUY NOW AND PAY LATER, WITH BLITZ.
        </Carousel.Item>
      </Carousel>
      </div>

  );
};

export default HeaderSlider;
