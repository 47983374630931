import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

import "./Header.css";

import HeaderSlider from "./HeaderSlider";
import logo from "../../assets/company_logo.png";
import HeaderDrawer from "./HeaderDrawer";

const Header = () => {
  const [userInfo, setUserInfo] = useState(false);
  const [name, setName] = useState("URSAG");

  const [HeaderScroll, setHeaderScroll] = useState(true);

  const [scrollPosition, setScrollPosition] = useState(0);

  const [HeadWidth, setHeadWidth] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    //console.log(position);
    setScrollPosition(position);
    // if(scrollPosition > 20 ){
    //   setHeaderScroll("false")
    // }
    if (position > 20) {
      // console.log(scrollPosition);
      setHeaderScroll(false);
    } else {
      setHeaderScroll(true);
    }
    // console.log(HeaderScroll);
  };

  const handleWidth = () => {
    const HeaderWidth = window.innerWidth;
    // console.log("WidthHeader", HeaderWidth);
    // setHeadWidth(HeaderWidth);
    // if(scrollPosition > 20 ){
    //   setHeaderScroll("false")
    // }

    if (HeaderWidth < 1102) {
      // console.log(scrollPosition);
      setHeadWidth(!HeadWidth);
    } else {
      setHeadWidth(false);
    }

    // if ( HeaderScroll === false && HeaderWidth < 1320) {
    //   console.log(HeaderScroll);
    //   setHeadWidth(!HeadWidth);
    // } else {
    //   setHeadWidth(false);
    // }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", handleWidth, { passive: true });
    if (window.innerWidth < 1102) {
      setHeadWidth(true);
    }
  }, []);

  const onChange = (val) => {
    console.log(val);
  };

  // window.innerWidth < 900 === setHeadWidth(true)

  return (
    <BrowserRouter>
      <div className="head" role="navigation">
        <HeaderSlider />

        <div className={HeaderScroll ? "inactive" : "navbar-inverse"}>
          <a href="/">
            <div className="brand">
              <img src={logo} height="50px" alt="Company_logo" />
            </div>
          </a>

          <div className="search-class2">
            <input
              type="text"
              placeholder="Search for products, brands and much more"
              onChange={onChange}
              className="searchText"
            />
          </div>

          {HeadWidth ? (
            <div className="collapseBtn">
              <HeaderDrawer userInfo={userInfo} name={name} />
            </div>
          ) : (
            <div className="Header-Partner">
              <div className="header-links Header-Partner">
                {userInfo ? (
                  <a to="/profile"></a>
                ) : (
                  <a href="/usersign">Be Our Seller/Partner </a>
                )}

                {userInfo ? (
                  <a to="/profile"></a>
                ) : (
                  <a href="/usersign">Join as fullfilment center</a>
                )}

                {userInfo ? (
                  <a to="/profile"></a>
                ) : (
                  <a href="/usersign">Be Our Sales Promotor </a>
                )}
              </div>

              <div className="header-links">
                {userInfo ? (
                  <a to="/profile">
                    <span style={{ color: "white" }}>Hi, {name}</span>
                  </a>
                ) : (
                  <a href="/usersign">Sign In</a>
                )}
                <div class="dropdown">
                  <div class="dropbtn">More</div>
                  <div class="dropdown-content">
                    <a href="/OrderHistory">My Orders</a>
                    <a href="#">My Profile</a>
                    <a href="#">Link 3</a>
                  </div>
                </div>
                <a href="/cart">Cart</a>
              </div>
            </div>
          )}
        </div>
      </div>
      <div></div>
    </BrowserRouter>
  );
};

export default Header;
