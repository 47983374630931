import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import allCAt from "../../src/assets/Mobile/allCat.png";
import Toolbar from "@material-ui/core/Toolbar";

import List from "@material-ui/core/List";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  rootOp: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

export default function OptionsSlider(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.rootOp}>
      {/* <CssBaseline /> */}
      {/* <AppBar
        
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      > */}
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          className={clsx(open && classes.hide)}
        >
          {/* <MenuIcon /> */}
          <div className="allCAt">
            <img src={allCAt} alt="allCAt" height="45px" />
            <div className="allCAtName">
              <span className="allCAtName">View All</span>
              <span className="allCAtName2"> Categories</span>
            </div>
          </div>
        </IconButton>
      </Toolbar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "left" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List className="ListOne">
          <a href="/usersign">Grocery</a>
          <a href="/usersign">Fashion</a>
          <a href="/usersign">Kids</a>
          <a href="/usersign">Electroincs</a>
          <a href="/usersign">Fooding</a>
        </List>
        <Divider />
        <List className="ListOne">
   
          <a href="/usersign">Fashion</a>
          <a href="/usersign">Fooding</a>
        </List>
        {/* <List className="ListOne">
          {props.userInfo ? (
            <a to="/profile">
              <span style={{ color: "white" }}>Hi, {props.name}</span>
              <a href="/cart">Cart</a>

              <div class="dropdown collpasedropdown">
                <div class="dropbtn">More</div>
                <div class="dropdown-content">
                  <a href="/OrderHistory">My Orders</a>
                  <a href="#">Link 2</a>
                  <a href="#">Link 3</a>
                </div>
              </div>
            </a>
          ) : (
            <a href="/usersign">Sign In</a>
          )}
        </List> */}
      </Drawer>
    </div>
  );
}
